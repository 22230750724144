import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { required } from 'helpers/validators';
import { IMovementReason } from 'interfaces';
import { useEffect, useState } from 'react';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';

interface MovementReasonsFormParams {
  movementReason: IMovementReason;
  onSubmit: any;
}

const directions = [
  {
    value: 1,
    display_name: 'IN'
  },
  {
    value: -1,
    display_name: 'OUT'
  }
];

export const MovementReasonsForm = (params: MovementReasonsFormParams) => {
  const { t } = useTranslation();
  const { movementReason, onSubmit } = params;
  const [discounted, setDiscounted] = useState(false);
  const [orderIndependent, setOrderIndependent] = useState(false);

  useEffect(() => {
    if (movementReason) {
      setDiscounted(movementReason.discounted);
      setOrderIndependent(movementReason.order_independent);
    }
  }, [movementReason]);

  return (
    <div>
      {movementReason && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!movementReason.id}>
          <div className="flex flex-wrap my-4">
            <div className="w-full md:w-2/3 my-2 md:pr-2">
              <IdentityField
                name="code"
                component={TextFieldAdapter}
                initialValue={movementReason.code}
                label={t('global.code')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full md:w-1/3 my-2 md:px-2">
              <IdentityField
                name="direction"
                component={AutocompleteAdapter}
                initValue={directions.find((s) => s.display_name === movementReason.direction)}
                defaultValue={
                  directions.find((s) => s.display_name === movementReason.direction).value
                }
                options={directions}
                optionLabel="display_name"
                optionValue="value"
                label={t('entities.movement.direction')}
                type="text"
                validate={required}
              />
            </div>
            <div className="w-full md:w-full my-2 md:px-0">
              <IdentityField
                name="description"
                component={TextFieldAdapter}
                initialValue={
                  movementReason.description
                    ? movementReason.description.split(' - ')[1] || movementReason.description
                    : ''
                }
                label={t('global.description')}
                placeholder={t('global.description')}
                type="text"
                validate={required}
              />
            </div>
            <div className="flex justify-between">
              <div className="my-2 md:pl-2">
                <IdentityField
                  name="discounted"
                  component={CheckboxAdapter}
                  initialValue={movementReason.discounted}
                  label={t('entities.movement.discounted')}
                  type="checkbox"
                />
              </div>
              <div className="my-2 md:pr-2">
                <IdentityField
                  name="order_independent"
                  component={CheckboxAdapter}
                  initialValue={movementReason.order_independent}
                  label={t('entities.movement.order_independent')}
                  type="checkbox"
                />
              </div>
              <div className="my-2 md:pr-2">
                <IdentityField
                  name="ddt"
                  component={CheckboxAdapter}
                  initialValue={movementReason.ddt}
                  label={t('entities.movement.ddt')}
                  type="checkbox"
                />
              </div>
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
};

export default MovementReasonsForm;
