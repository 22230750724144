import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { filterOperators, numberFilterOperators } from '../../../../constants';
import { baseCell } from '../cells/base-cell';
import { dateCell } from '../cells/date-cell';
import ConfirmationDeleteTableDialog from 'components/helpers/ConfirmationGridDelete';
import EditIcon from '@mui/icons-material/Edit';

export function TransportActiveDocumentLinesColumns(
  handleDelete?: any,
  handleEdit?: (id: number) => void
): GridColumns {
  const translationPrefix = 'pages.active-ddt.product-list';
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'quantity',
      headerName: t(`${translationPrefix}.quantity`),
      width: 80,
      type: 'number',
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'product_code',
      headerName: t(`${translationPrefix}.product_code`),
      width: 175,
      renderCell: baseCell
    },
    {
      field: 'product_description',
      headerName: t(`${translationPrefix}.product_description`),
      width: 250,
      sortable: false,
      filterable: false,
      renderCell: baseCell
    },
    {
      field: 'product_batch',
      headerName: t(`${translationPrefix}.product_batch`),
      width: 100,
      sortable: false,
      renderCell: baseCell
    },
    {
      field: 'expiration_date',
      headerName: t(`${translationPrefix}.expiration_date`),
      width: 100,
      sortable: false,
      renderCell: dateCell
    },
    {
      field: 'notes',
      headerName: t(`${translationPrefix}.notes`),
      filterOperators,
      sortable: false,
      editable: false,
      width: 250,
      renderCell: baseCell
    }
  ];

  columns.push({
    field: 'actions',
    type: 'actions',
    headerName: t(`global.actions`),
    getActions: (params: GridRowParams) => {
      const actions = [];
      actions.push(
        <GridActionsCellItem
          key={1}
          label={t('actions.edit')}
          icon={<EditIcon />}
          onClick={() => handleEdit(params.row)}
        />
      );
      actions.push(
        <ConfirmationDeleteTableDialog handleDelete={handleDelete} id={params.row.id} />
      );

      return actions;
    }
  });

  return columns;
}
