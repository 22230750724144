import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import { TenantPage } from 'components/common';
import SectionTitle from 'components/common/SectionTitle';
import { IInvoice, ITransportDocument } from 'interfaces';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  equipmentInvoiceService,
  invoiceService,
  transportDocumentLineService,
  transportDocumentService
} from 'services/api';
import AddIcon from '@mui/icons-material/Add';
import { currencyFormatter } from 'helpers/currency-formatter';
import FileUploader from 'components/common/file-uploader';
import ReactFinalForm from 'components/form/ReactFinalForm';
import { IdentityField } from 'components/form/IdentityField';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';

import dayjs from 'dayjs';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import toast from 'features/toast';
import { CheckboxAdapter } from 'components/form/CheckboxAdapter';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockIcon from '@mui/icons-material/Lock';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TransportDocumentForm from './TransportDocumentForm';
import DifferenceIcon from '@mui/icons-material/Difference';
import { TransportDocumentLinesColumns } from 'components/common/enhanced/common-headers/transport-document-lines';
import { useSnackbar } from 'notistack';
import { DocumentTableCardComponent } from 'components/common/documentLM/DocumentTableCardComponent';
import { TransportDocumentColumns } from 'components/common/enhanced/common-headers/transport-document';

export function InvoiceDetail() {
  const { t } = useTranslation();
  const prefix = 'pages.invoices.detail';
  const { invoiceId } = useParams();
  const datagridRefresh = useRef(null);
  const unplugDatagridRefresh = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [openDialog, setOpenDialog] = useState(false);
  const [invoice, setInvoice] = useState<IInvoice>();
  const [openErrorColor, setOpenErrorColor] = useState(false);

  const refreshInvoice = () => {
    invoiceService.get(+invoiceId).then(setInvoice);
  };

  useEffect(() => {
    refreshInvoice();
  }, []);

  const downloadableFields = [
    'invoice_file',
    'invoice_revision_file',
    'extra_1_file',
    'extra_2_file',
    'extra_3_file'
  ];

  const updateInvoice = (values) => {
    invoiceService.update(+invoiceId, values).then((res) => {
      setInvoice(res);
      toast.success('Fattura aggiornata con successo');
    });
  };

  const downloadCreditMemo = () => {
    invoiceService.downloadCreditMemo(+invoiceId);
  };

  const downloadInvoice = () => {
    invoiceService.downloadInvoiceAgreement(+invoiceId);
  };
  const downloadCDFrontspiece = () => {
    invoiceService.downloadCDFrontspieceAgreement(+invoiceId);
  };

  const updateSettings = () => {
    enqueueSnackbar('Nessun numero di nota di credito/debito inserito', { variant: 'error' });
    setOpenErrorColor(true);
  };

  const sendCreditMemo = () => {
    invoiceService.emailCreditMemo(+invoiceId);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onSubmit = (values) => {
    transportDocumentService
      .update(+values.transport_document, { invoice: +invoiceId })
      .then((res) => {
        handleCloseDialog();
        datagridRefresh.current();
        refreshInvoice();
        setOpenErrorColor(false);
      });
  };

  const generateCreditMemo = () => {
    invoiceService.generateCreditMemo(+invoiceId).then(() => {
      refreshInvoice();
    });
  };

  const unplugDDTfromInvoice = (ddtId) => {
    transportDocumentService.update(+ddtId, { invoice: null }).then((res) => {
      toast.success(t(`${prefix}.unplug-success`));
      unplugDatagridRefresh.current();
      datagridRefresh.current();
    });
  };

  const columns = TransportDocumentLinesColumns(false, false);
  const unplugColumns = TransportDocumentColumns(false, null, unplugDDTfromInvoice);

  // ROWS
  // const rowsDDT = useRef([]);

  return (
    <TenantPage
      title={t(`${prefix}.title`) + ' ' + invoice?.ref}
      subtitle={t(`${prefix}.subtitle`)}
      entityCode={invoice?.ref}
      menuRight={
        <div className="flex flex-wrap">
          <ButtonGroup size="small" className="mr-4">
            <Button sx={{ pointerEvents: 'none' }}>{t(`${prefix}.credit-note`)}</Button>
            <Button onClick={generateCreditMemo}>
              <DifferenceIcon />
            </Button>
            {invoice && invoice.credit_memo_file && (
              <>
                <Button onClick={downloadCreditMemo}>
                  <DownloadIcon />
                </Button>
                <Button onClick={sendCreditMemo}>
                  <SendIcon />
                </Button>
              </>
            )}
          </ButtonGroup>
          {invoice &&
            invoice.credit_memo_file &&
            invoice.amount != null &&
            +invoice.amount !== +invoice.expected_amount && (
              <ButtonGroup size="small" className="mr-4">
                <Button sx={{ pointerEvents: 'none' }}>
                  {t(`${prefix}.invoice-credit-debit-agreement`)}
                </Button>
                {invoice.credit_memo_ref === '' && (
                  <Button onClick={updateSettings}>
                    <FileDownloadOffIcon />
                  </Button>
                )}
                {invoice.credit_memo_ref !== '' && (
                  <Button onClick={downloadCDFrontspiece}>
                    <DownloadIcon />
                  </Button>
                )}
              </ButtonGroup>
            )}
          <ButtonGroup size="small" className="mr-4">
            <Button sx={{ pointerEvents: 'none' }}>{t(`${prefix}.invoice-agreement`)}</Button>
            <Button onClick={downloadInvoice}>
              <DownloadIcon />
            </Button>
          </ButtonGroup>
          <ButtonGroup size="small">
            <Button onClick={() => setOpenDialog(true)}>
              <AddIcon />
            </Button>
          </ButtonGroup>
        </div>
      }>
      <div>
        <div className="w-full">
          {invoice && invoice.amount != null && +invoice.amount !== +invoice.expected_amount && (
            <Alert severity="warning" className="w-full">
              {t(`${prefix}.incoerent-amounts`)}
            </Alert>
          )}
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.header`)}</SectionTitle>
          <div className="flex flex-wrap my-4">
            <div className="w-3/4 pr-8">
              {invoice && (
                <ReactFinalForm onSubmit={updateInvoice} hideControls={true} formId="amountForm">
                  <div className="flex-col">
                    <div className="flex mb-5 items-center ">
                      <div className="mr-4">
                        <IdentityField
                          name="ref"
                          label={t(`entities.ddt.invoice_ref`)}
                          initialValue={invoice.ref}
                          component={TextFieldAdapter}
                          disabled={invoice.closed}
                        />
                      </div>
                      <div className="mr-4">
                        <IdentityField
                          name="date"
                          label={t(`entities.ddt.invoice_date`)}
                          component={DatePickerAdapter}
                          initialValue={dayjs(invoice.date, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                          disabled={invoice.closed}
                        />
                      </div>
                      <div className="mr-4">
                        <IdentityField
                          name="shipping_fare"
                          label={t(`entities.ddt.shipping_fare`)}
                          initialValue={invoice.shipping_fare}
                          component={TextFieldAdapter}
                          type="number"
                        />
                      </div>
                      <div
                        className={`mr-4 ${
                          openErrorColor && invoice.credit_memo_ref == ''
                            ? 'border-2 border-rose-500 text-red-500'
                            : ''
                        }`}>
                        <IdentityField
                          name="credit_memo_ref"
                          label={t(`entities.ddt.credit_memo_ref`)}
                          initialValue={invoice.credit_memo_ref}
                          component={TextFieldAdapter}
                          type="text"
                          disabled={+invoice.credit_memo_amount === 0}
                        />
                      </div>
                      <div className="mr-4">
                        <IdentityField
                          name="credit_memo_amount"
                          label={t(`entities.ddt.credit_memo_amount`)}
                          initialValue={currencyFormatter(invoice.credit_memo_amount)}
                          component={TextFieldAdapter}
                          type="text"
                          disabled
                        />
                      </div>
                      <div
                        className={`mr-4 ${
                          openErrorColor && invoice.credit_memo_ref == ''
                            ? 'border-2 border-rose-500 text-red-500'
                            : ''
                        }`}>
                        <IdentityField
                          name="credit_memo_date"
                          label={t(`entities.ddt.credit_memo_date`)}
                          component={DatePickerAdapter}
                          initialValue={
                            invoice?.credit_memo_date
                              ? dayjs(invoice.credit_memo_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
                              : null
                          }
                          disabled={invoice.closed || +invoice.credit_memo_amount === 0}
                          disableOpenPicker={invoice.closed || +invoice.credit_memo_amount === 0}
                        />
                      </div>
                      <div className="mr-4">
                        <IdentityField
                          name="closed"
                          label={
                            invoice?.closed ? t(`entities.ddt.closed`) : t(`entities.ddt.open`)
                          }
                          component={CheckboxAdapter}
                          type="checkbox"
                          initialValue={invoice?.closed ?? false}
                          icon={<LockOpenOutlinedIcon />}
                          checkedIcon={<LockIcon />}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="w-full flex gap-5">
                        <IdentityField
                          name="notes"
                          label={t(`global.notes`)}
                          component={TextFieldAdapter}
                          initialValue={invoice.notes}
                        />
                        <IconButton type="submit" form="amountForm" color="primary">
                          <div className="flex items-center gap-2 w-[110px]">
                            <SaveIcon /> <div>{t(`actions.save`)}</div>
                          </div>
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </ReactFinalForm>
              )}
            </div>
            <div className="w-1/4">
              {invoice && (
                <div>
                  <b>{t(`entities.ddt.amount`)}</b>
                  <div className="flex flex-wrap">{currencyFormatter(invoice.amount)}</div>
                  <b>{t(`${prefix}.invoice-expected`)}</b>
                  <div className="flex flex-wrap">{currencyFormatter(invoice.expected_amount)}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mb-4">
          <SectionTitle>{t(`${prefix}.files`)}</SectionTitle>
          {invoice && (
            <div className="flex flex-wrap">
              {downloadableFields.map((field, i) => (
                <div key={i} className={`w-1/${downloadableFields.length}`}>
                  <span>{t(`entities.ddt.${field}`)}:</span>
                  <FileUploader
                    service={equipmentInvoiceService}
                    entityId={+invoiceId}
                    attachmentName={field}
                    filename={invoice[field]}
                  />
                </div>
              ))}
            </div>
          )}

          {invoice && (
            <div className="w-full p-4">
              <Card variant="outlined">
                <DocumentTableCardComponent
                  originalService={'invoice'}
                  objectId={invoice.id}
                  dictParams={{ invoice: invoiceId }}
                  folder={'menu.invoices/' + invoice.ref}
                  folder_description={`Data: ${invoice.date} `}
                />
              </Card>
            </div>
          )}
        </div>
        {/* Unplug DDT from Invoice */}
        {invoice && (
          <div>
            <div className="m-4 gap-2">
              <SectionTitle>{t(`${prefix}.invoice-ddts`)}</SectionTitle>
            </div>
            <EnhancedDataGrid
              columns={unplugColumns}
              service={transportDocumentService}
              outerParams={{ invoice_ref: invoice.ref }}
              refresh={unplugDatagridRefresh}
              // rows={rowsDDT}
              disableDelete
            />
          </div>
        )}

        {invoice && (
          <div className="mb-4 mt-6">
            <SectionTitle>{t(`pages.ddt.detail.movement-list`)}</SectionTitle>
            <Dialog open={openDialog} maxWidth="lg" fullWidth>
              <DialogTitle>{t(`${prefix}.add-transport-document`)}</DialogTitle>
              <DialogContent>
                <TransportDocumentForm onSubmit={onSubmit} supplierId={+invoice.supplier_id} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="error">
                  {t('actions.cancel')}
                </Button>
                <Button type="submit" form="newInvoice">
                  {t(`actions.confirm`)}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
        {invoice && (
          <EnhancedDataGrid
            columns={columns}
            service={transportDocumentLineService}
            outerParams={{ invoice: invoiceId }}
            refresh={datagridRefresh}
          />
        )}
      </div>
    </TenantPage>
  );
}

export default InvoiceDetail;
