import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation(); // Assuming you're using react-i18next for translations

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {t('pages.purchase-requests.new.quantity-multiple-confirmation-title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('pages.purchase-requests.new.quantity-multiple-confirmation-text')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('actions.cancel')}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {t('actions.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
