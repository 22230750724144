import { examService } from 'services/api';
import { IExam } from 'interfaces';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import ExamForm from '../ExamForm';
import { useEffect, useState, useRef } from 'react';
import { Card, CardContent } from '@mui/material';
import EntityHistory from 'components/widgets/EntityHistory';
import { DocumentTableCardComponent } from 'components/common/documentLM/DocumentTableCardComponent';
import toast from 'features/toast';
import { HistoryLineList } from '../../../common/HistoryList/list/HisotryLineList';
import { ExamHistoryLineColumns } from 'components/common/enhanced/common-headers/exam-history-lines';
import { examHistoryLineService } from 'services/api/history/ExamHistoryService';

export const EditExam = () => {
  const translationPrefix = 'pages.exams.edit';
  const { t } = useTranslation();
  const { examId } = useParams();

  const [exam, setExam] = useState<IExam>();

  useEffect(() => {
    examService.get(+examId).then(setExam);
  }, []);

  // const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const update = async (values) => {
    dispatch(loadingActions.startLoading());
    examService.update(exam.id, values).then(() => {
      toast.success(t(`${translationPrefix}.success`));
    });
    dispatch(loadingActions.stopLoading());
  };

  // -------------------------------------- HISTORY MANAGEMENT
  const historyRefresher = useRef(null);
  const handleDateTimeUpdate = (id: number, datetime: string) => {
    examHistoryLineService.update(id, { datetime: datetime }).then(() => {
      toast.success(t('actions.successfully.updated'));
      if (historyRefresher.current) {
        historyRefresher.current(); // ✅ Assicura che venga chiamata solo se assegnata
      }
    });
  };

  const handleDeleteLine = (id: number) => {
    examHistoryLineService.delete(id).then(() => {
      toast.success(t('actions.successfully.deleted'));
      if (historyRefresher.current) {
        historyRefresher.current(); // ✅ Assicura che venga chiamata solo se assegnata
      }
    });
  };

  const manualRefresh = () => {
    if (historyRefresher.current) {
      historyRefresher.current(); // ✅ Chiama la funzione di refresh se esiste
    }
  };

  const historyColumns = ExamHistoryLineColumns(handleDateTimeUpdate, handleDeleteLine, false);

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`, { id: examId })}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={exam?.code}>
      <ExamForm exam={exam} onSubmit={update} />
      {exam && (
        <>
          <div className="">
            <Card variant="outlined">
              {/* This section is for the document management of the exam */}
              <DocumentTableCardComponent
                originalService={'exam'}
                dictParams={{ exam: exam.id }}
                objectId={exam.id}
                folder={'menu.exams/' + exam.code}
                folder_description={`Descrizione: ${exam.description}; Etichetta: ${exam.category}`}
              />
            </Card>
          </div>
          <br />
          <div className="">
            <Card variant="outlined">
              {/* This section handle the history_exam entity */}
              <HistoryLineList
                service={examHistoryLineService}
                columns={historyColumns}
                outerParams={{ exam: exam.code }}
                refresh={historyRefresher}
                manualRefresh={manualRefresh}
              />
            </Card>
          </div>
          <br />
          <div className="flex flex-wrap  mt-4">
            <div className="w-full md:w-1/2">
              <Card variant="outlined">
                <CardContent>
                  <EntityHistory service={examService} entityId={exam.id} />
                </CardContent>
              </Card>
            </div>
          </div>
        </>
      )}
    </TenantPage>
  );
};

export default EditExam;
