import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import {
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography
} from '@mui/material';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import {
  examService,
  laboratoryService,
  medicalServiceService,
  workstationService
} from 'services/api';
import { IdentityField } from 'components/form/IdentityField';
import {
  booleanFilterOperators,
  dateFilterOperators,
  filterOperators,
  numberFilterOperators
} from '../../../../constants';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { IMedicalService, IMedicalServiceEtlField, IOption } from 'interfaces';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { loadingActions } from 'features';
import { required } from 'helpers/validators';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useAppDispatch } from 'app/store';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import CollapsableColumns from 'components/common/CollapsableColumns';
import DataGridCell from 'components/common/DataGridCell';
import dayjs from 'dayjs';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import ReactFinalForm from 'components/form/ReactFinalForm';
import TenantPage from 'components/common/TenantPage';
import { baseCell } from 'components/common/enhanced/cells/base-cell';
import toast from 'features/toast';
import { PurgeButton } from 'components/helpers/PurgeButton';
import LISFileUploader from './LISFileImport';
import { Check, Close } from '@mui/icons-material';
import ImportFileUploader from 'components/common/file-uploader/ImportFileUploader';
import StyledDropzone from 'components/common/StyledDropzone';
import { medicalServiceEtlFieldService } from 'services/api/MedicalServiceEtlFieldService';
import { set } from 'lodash';

export const MedicalServices = () => {
  const translationPrefix = 'pages.medical-services.list';

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const datagridRefresh = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [openDialog, setOpenDialog] = useState(false);
  const [openLisDialog, setOpenLisDialog] = useState(false);
  const [openImportLisDialog, setOpenImportLisDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IMedicalService | null>(null);

  const [exams, setExams] = useState<IOption[]>([]);
  const [exam, setExam] = useState<IOption>();
  const [laboratories, setLaboratories] = useState<IOption[]>([]);
  const [lab, setLab] = useState<IOption>();
  const [workstations, setWorkstations] = useState<IOption[]>([]);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [extraFields, setExtraFields] = useState<IMedicalServiceEtlField[]>([]);
  const [etlHeaderNames, setEtlHeaderNames] = useState<{
    quantity?: string;
    exam_code?: string;
    service_date?: string;
    request_date?: string;
    regional_code?: string;
    req_department?: string;
    req_laboratory?: string;
    exec_laboratory?: string;
    workstation?: string;
  }>({});

  const handleDrop = (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      toast.error(t('global.import-size-error'));
      return;
    }

    const file = acceptedFiles[0];
    if (file) {
      setFileToUpload(file);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'exam',
      headerName: etlHeaderNames.exam_code
        ? etlHeaderNames.exam_code
        : t(`${translationPrefix}.entity.exam`),
      flex: 1,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'registered',
      headerName: t(`global.registered`),
      width: 80,
      sortable: false,
      filterOperators: booleanFilterOperators,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          sx={{ background: 'transparent' }}
          icon={params.value ? <Check color="success" /> : <Close color="error" />}
        />
      )
    },
    {
      field: 'quantity',
      headerName: etlHeaderNames.quantity ? etlHeaderNames.quantity : t(`global.quantity`),
      flex: 1,
      filterOperators: numberFilterOperators,
      renderCell: baseCell
    },
    {
      field: 'request_date',
      headerName: t(`${translationPrefix}.entity.request_date`),
      flex: 1,
      filterOperators: dateFilterOperators,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={params.value && dayjs(params.value).format('DD/MM/YYYY')} />
      )
    },
    {
      field: 'service_date',
      headerName: t(`${translationPrefix}.entity.service_date`),
      flex: 1,
      filterOperators: dateFilterOperators,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={dayjs(params.value).format('DD/MM/YYYY')} />
      )
    },
    {
      field: 'req_department',
      headerName: t(`${translationPrefix}.entity.request_department`),
      flex: 1,
      filterOperators,
      editable: true,
      renderCell: baseCell
    },
    {
      field: 'req_laboratory',
      headerName: t(`${translationPrefix}.entity.request_laboratory`),
      flex: 1,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'exec_laboratory',
      headerName: t(`${translationPrefix}.entity.execution_laboratory`),
      flex: 1,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'workstation',
      headerName: t(`global.workstation`),
      flex: 1,
      filterOperators,
      renderCell: baseCell
    }
    // {
    //   field: 'actions',
    //   headerName: t(`${translationPrefix}.entity.actions`),
    //   renderCell: (params: GridRenderCellParams) => {
    //     const product = params.row;
    //     return (
    //       <div>
    //         <EditIcon className="cursor-pointer" onClick={() => editMode(product)} />
    //       </div>
    //     );
    //   },
    //   flex: 1,
    //   filterable: false
    // }
  ];

  if (extraFields.length > 0) {
    extraFields.forEach((f) => {
      columns.push({
        field: `${f.field_to}`,
        headerName: `${f.transcode}`,
        flex: 1,
        filterOperators,
        renderCell: baseCell
      });
    });
  }

  useEffect(() => {
    dispatch(loadingActions.startLoading());
    examService.getAllBaseInformation().then(setExams);
    laboratoryService.getAllBaseInformation().then(setLaboratories);
    dispatch(loadingActions.stopLoading());
    medicalServiceEtlFieldService.getAllPaginated().then((res) => {
      if (res.results.length > 0) {
        setEtlColumnsNames(res.results);
        const extraFields = res.results.filter((item) => item.field_to.startsWith('extra'));
        setExtraFields(extraFields);
      }
    });
  }, []);

  useEffect(() => {
    if (!exam || !lab) return;
    setWorkstations([]);
    workstationService
      .getAllBaseInformation({ exam: exam.id, laboratory: lab.id })
      .then(setWorkstations);
  }, [lab]);

  const setEtlColumnsNames = (etlFields: IMedicalServiceEtlField[]) => {
    setEtlHeaderNames({
      quantity: etlFields.find((etlField) => etlField?.field_to === 'quantity')?.transcode,
      exam_code: etlFields.find((etlField) => etlField?.field_to === 'exam_code')?.transcode,
      service_date: etlFields.find((etlField) => etlField?.field_to === 'service_date')?.transcode,
      request_date: etlFields.find((etlField) => etlField?.field_to === 'request_date')?.transcode,
      regional_code: etlFields.find((etlField) => etlField?.field_to === 'regional_code')
        ?.transcode,
      req_department: etlFields.find((etlField) => etlField?.field_to === 'req_department')
        ?.transcode,
      req_laboratory: etlFields.find((etlField) => etlField?.field_to === 'req_laboratory')
        ?.transcode,
      exec_laboratory: etlFields.find((etlField) => etlField?.field_to === 'exec_laboratory')
        ?.transcode,
      workstation: etlFields.find((etlField) => etlField?.field_to === 'workstation')?.transcode
    });
  };

  const onOpenDialog = () => {
    setOpenDialog(!openDialog);
    setEdit(false);
  };

  const onOpenLisDialog = () => {
    setOpenLisDialog(!openLisDialog);
  };

  const onOpenImportLisDialog = () => {
    setOpenImportLisDialog(!openImportLisDialog);
  };

  // DEPRECATED: editMode rimossa nel 2024
  // const editMode = (service) => {
  //   dispatch(loadingActions.startLoading());
  //   medicalServiceService.get(service.id).then((res) => {
  //     setSelectedItem(res);
  //     setOpenDialog(true);
  //     setEdit(true);
  //   });
  //   dispatch(loadingActions.stopLoading());
  // };

  const onSubmitCreate = async (values) => {
    medicalServiceService.generateLines(values).then(() => {
      toast.success('Prestazioni mediche aggiunte.');
      datagridRefresh.current();
    });
  };

  // DEPRECATED: onSubmitEdit rimossa nel 2024
  // const onSubmitEdit = async (values) => {
  //   dispatch(loadingActions.startLoading());
  //   medicalServiceService.update(selectedItem.id, values).then(() => {
  //     enqueueSnackbar('Medical service updated successfully', { variant: 'success' });
  //     datagridRefresh.current();
  //   });
  //   dispatch(loadingActions.stopLoading());
  // };

  const handleUpload = () => {
    if (fileToUpload) {
      medicalServiceService.importAggregatedLinesFromFile(fileToUpload).then(() => {
        if (onUploadSuccess) onUploadSuccess();
        toast.success(t('global.import-started'));
        setOpenImportLisDialog(false);
      });
    }
  };

  const handleClose = () => {
    setFileToUpload(null);
    setOpenImportLisDialog(false);
  };

  const onUploadSuccess = () => {
    toast.success('Importazione avviata.');
  };

  const onImportUploadSuccess = () => {
    toast.success('Importazione Lis avviata.');
  };

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <div>
              <ButtonGroup>
                <IsTenantAdministrator>
                  <Button color="success" onClick={onOpenImportLisDialog}>
                    <ReceiptIcon />
                    {t(`${translationPrefix}.import-lis`)}
                  </Button>
                  <Button onClick={onOpenLisDialog}>
                    <ReceiptIcon />
                    {t(`${translationPrefix}.add-lis`)}
                  </Button>
                  <Button onClick={onOpenDialog}>
                    <AddIcon />
                    {t(`${translationPrefix}.add`)}
                  </Button>
                </IsTenantAdministrator>
              </ButtonGroup>
            </div>
            <PurgeButton service={medicalServiceService} refresh={datagridRefresh} />
          </div>
        }>
        <CollapsableColumns
          expand={openDialog}
          onClose={() => {
            setSelectedItem(null);
            setOpenDialog(false);
          }}
          size="w-1/3"
          contentLeft={
            <EnhancedDataGrid
              refresh={datagridRefresh}
              service={medicalServiceService}
              columns={columns}
            />
          }
          contentRight={
            <div className="w-full flex flex-wrap">
              <h2 className="h2 font-bold text-lg text-center ">
                {edit && selectedItem ? `EDIT MEDICAL SERVICE ${selectedItem.ref}` : 'ADD NEW'}
              </h2>
              <ReactFinalForm onSubmit={onSubmitCreate}>
                <div className="flex flex-wrap my-4">
                  <div className="w-full md:w-full my-2">
                    <IdentityField
                      name="exam"
                      component={AutocompleteAdapter}
                      options={exams}
                      setValue={setExam}
                      label={t(`${translationPrefix}.entity.exam`)}
                      type="text"
                      optionLabel="description"
                      optionValue="code"
                      validate={required}
                      required
                    />
                  </div>
                  <div className="w-full md:w-full my-2">
                    <IdentityField
                      name="laboratory"
                      component={AutocompleteAdapter}
                      options={laboratories}
                      setValue={setLab}
                      label={t(`global.laboratory`)}
                      type="text"
                      optionValue="code"
                      validate={required}
                      required
                    />
                  </div>
                  <div className="w-full md:w-full my-2">
                    <IdentityField
                      name="request_laboratory"
                      component={AutocompleteAdapter}
                      options={laboratories}
                      label={t(`global.request_laboratory`)}
                      type="text"
                      optionValue="code"
                      validate={required}
                      required
                    />
                  </div>
                  <div className="w-full md:w-full my-2">
                    <IdentityField
                      name="workstation"
                      component={AutocompleteAdapter}
                      options={workstations}
                      label={t(`global.workstation`)}
                      type="text"
                      optionValue="code"
                    />
                  </div>
                  <div className="w-full my-2 md:pr-1">
                    <IdentityField
                      name="date"
                      component={DatePickerAdapter}
                      label={t(`${translationPrefix}.entity.service_date`)}
                      type="date"
                      validate={required}
                      required
                    />
                  </div>
                  <div className="w-full md:w-2/3 my-2 md:pr-1">
                    <IdentityField
                      name="request_date"
                      component={DatePickerAdapter}
                      label={t(`${translationPrefix}.entity.request_date`)}
                      type="date"
                      validate={required}
                      required
                    />
                  </div>
                  <div className="w-full md:w-1/3 my-2 md:pl-1">
                    <IdentityField
                      name="quantity"
                      component={TextFieldAdapter}
                      label={t(`${translationPrefix}.entity.quantity`)}
                      type="number"
                      initialValue="1"
                      required
                    />
                  </div>
                </div>
              </ReactFinalForm>
            </div>
          }
        />
        <LISFileUploader
          openDropzone={openLisDialog}
          setOpenDropzone={setOpenLisDialog}
          onUploadSuccess={onUploadSuccess}
          showButton={false}
        />
        <Dialog open={openImportLisDialog}>
          <DialogTitle>{t(`import-modal.title`)}</DialogTitle>
          <DialogContent>
            <Typography color="textSecondary">{t(`${translationPrefix}.subtitle`)}</Typography>
            <StyledDropzone onDrop={handleDrop} />
            <div className="mt-4">
              <Link
                underline="hover"
                onClick={() => medicalServiceService.getImportTemplate()}
                className="cursor-pointer">
                {t('actions.download-template')}
              </Link>
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>{t('actions.cancel')}</Button>
            <Button onClick={handleUpload} disabled={!fileToUpload}>
              {t('actions.upload')}
            </Button>
          </DialogActions>
        </Dialog>
        {openImportLisDialog && (
          <ImportFileUploader service={medicalServiceService} onUploadSuccess={onUploadSuccess} />
        )}
      </TenantPage>
    </>
  );
};

export default MedicalServices;
