import { SyntheticEvent, useEffect, useState } from 'react';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';

import { useField } from 'react-final-form';
import { Box, Popper, TextField, styled } from '@mui/material';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';
import React from 'react';

export const AutocompleteAdapter = ({
  input: { name },
  options,
  optionLabel,
  optionValue,
  initValue,
  setValue,
  submitObject,
  freeSolo,
  disabled,
  getMethodService,
  autoSelect, // BOOLEAN: changed in useEffect to set _value=null, autoComplete if option==1
  ...rest
}: any) => {
  const { input, meta } = useField(name);
  const optLabel = optionLabel ?? 'code';
  const optValue = optionValue ?? 'id';

  const onChange = (e: SyntheticEvent, value) => {
    _setValue(value);
    if (value) {
      if (submitObject === true) input.onChange(value);
      else input.onChange(typeof value === 'string' ? value : value[optValue] ?? '');
    } else {
      input.onChange('');
    }
    if (setValue) {
      setValue(value);
    }
  };

  const handleDisabled = () => {
    if (freeSolo === true) return false;
    return !options.length;
  };

  const [_value, _setValue] = useState(null);

  useEffect(() => {
    if (!initValue) {
      if (options && options.length === 1 && autoSelect !== false) {
        // Auto-select the single available option if autoSelect is not explicitly false
        onChange(null, options[0]);
      } else if (
        options &&
        options.length > 1 &&
        options.length < 20 &&
        _value !== null &&
        autoSelect !== false
      ) {
        // Use find method to search for a matching option based on the code property
        const matchedOption = options.find((option) => option.code === _value.code);

        if (matchedOption) {
          // If a matching option is found, trigger onChange with the matched option
          onChange(null, matchedOption);
        } else {
          // If no matching option is found, trigger onChange with null
          onChange(null, null);
        }
      }
    } else {
      // If an initial value is provided, trigger onChange with the initial value
      onChange(null, initValue);
    }
  }, [options]);

  // Start strange stuff
  const LISTBOX_PADDING = 16; // px

  function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
      lineHeight: '12px',
      paddingTop: '4px',
      fontSize: '12px',
      paddingBottom: '4px'
    };

    return (
      <Box component="li" {...dataSet[0]} style={inlineStyle}>
        {`${dataSet[0].key}`}
      </Box>
    );
  }

  const OuterElementContext = React.createContext({});

  // eslint-disable-next-line react/display-name
  const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  // Adapter for react-window
  const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
      const { children, ...other } = props;
      const itemData: React.ReactElement[] = [];
      (children as React.ReactElement[]).forEach(
        (item: React.ReactElement & { children?: React.ReactElement[] }) => {
          itemData.push(item);
          itemData.push(...(item.children || []));
        }
      );

      const theme = useTheme();
      const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true
      });
      const itemCount = itemData.length;
      const itemSize = smUp ? 36 : 48;

      const getChildSize = (child: React.ReactElement) => {
        // eslint-disable-next-line no-prototype-builtins
        if (child.hasOwnProperty('group')) {
          return 48;
        }

        return itemSize;
      };

      const getHeight = () => {
        if (itemCount > 8) {
          return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
      };

      const gridRef = useResetCache(itemCount);

      return (
        <div ref={ref}>
          <OuterElementContext.Provider value={other}>
            <VariableSizeList
              itemData={itemData}
              height={getHeight() + 2 * LISTBOX_PADDING}
              width="100%"
              ref={gridRef}
              outerElementType={OuterElementType}
              innerElementType="ul"
              itemSize={(index) => getChildSize(itemData[index])}
              overscanCount={5}
              itemCount={itemCount}>
              {renderRow}
            </VariableSizeList>
          </OuterElementContext.Provider>
        </div>
      );
    }
  );

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0
      }
    }
  });

  return (
    <Autocomplete
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={options}
      disabled={disabled ? true : handleDisabled()}
      getOptionLabel={(option) => option[optLabel] ?? ''} // ADDED
      defaultValue={initValue ?? null}
      renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...rest}
            helperText={meta.touched ? meta.error : ''}
            error={meta.error && meta.touched}
            fullWidth
          />
        );
      }}
      isOptionEqualToValue={(option, value) => option[optValue] === value[optValue]} // ADDED
      onChange={onChange} // ADDED
      value={_value} // ADDED
    />
  );
  // END strange stuff
};
