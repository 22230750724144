import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams
} from '@mui/x-data-grid-premium';
import DataGridCell from 'components/common/DataGridCell';
import { useTranslation } from 'react-i18next';
import { filterOperators } from '../../../../constants';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PanToolIcon from '@mui/icons-material/PanTool';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Tooltip } from '@mui/material';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { IsTenantCoordinator } from 'components/helpers/IsTenantCoordinator';
import VerifiedIcon from '@mui/icons-material/Verified';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { baseCell } from '../cells/base-cell';
import { booleanCell } from '../cells/boolean-cell';
import EditIcon from '@mui/icons-material/Edit';

export function AutomaticPurchaseLinesColumns(
  reject,
  approve,
  preApprove,
  handleDelete,
  handleEdit
): GridColumns {
  const { t } = useTranslation();

  const columns: GridColumns = [
    {
      field: 'index',
      headerName: t('global.count'),
      width: 50,
      filterable: false,
      sortable: false
    },
    {
      field: 'is_periodic',
      headerName: t(`global.is_periodic`),
      width: 50,
      valueGetter: (params: GridRenderCellParams<boolean>) =>
        params.row.is_periodic ? 'SI' : 'NO',
      renderCell: (params: GridRenderCellParams<boolean>) =>
        params.value === true && (
          <Tooltip title="is_periodic" placement="top">
            <EventRepeatIcon color="info" />
          </Tooltip>
        )
    },
    {
      field: 'status',
      headerName: t(`entities.purchase_request.status-field`),
      width: 100,
      renderCell: (params: GridRenderCellParams<string>) => (
        <DataGridCell content={t(`entities.purchase_request.status.s-${params.value}`)} />
      )
    },
    {
      field: 'product',
      headerName: t('global.product'),
      width: 200,
      filterOperators,
      valueGetter: (params) => params.row.product?.code || '', // Usa solo `code`
      renderCell: (params: GridRenderCellParams<string>) => <DataGridCell content={params.value} />
    },
    {
      field: 'description',
      headerName: t(`entities.product.description`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'product.order_notes',
      headerName: t(`entities.product.order_notes`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'contract',
      headerName: t(`global.contract`),
      width: 100,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'quantity',
      headerName: t(`entities.purchase_request.quantity`),
      width: 100,
      type: 'number',
      renderCell: baseCell
    },
    {
      field: 'discounted',
      headerName: t('entities.movement.discounted'),
      width: 100,
      type: 'boolean',
      renderCell: booleanCell(t)
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      width: 200,
      filterOperators,
      renderCell: baseCell
    },
    {
      field: 'requested_shipment_date',
      headerName: t(`entities.purchase_request.requested_shipment_date`),
      width: 200,
      filterOperators,
      type: 'date',
      renderCell: baseCell
    },
    {
      field: 'notes',
      headerName: t(`entities.purchase_request.notes`),
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'actions',
      type: 'actions',
      width: 150,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={1}
          icon={
            <Tooltip title={t('actions.disapprove')} placement="top">
              <PanToolIcon color={params.row.status === 4 ? 'warning' : 'disabled'} />
            </Tooltip>
          }
          onClick={() => reject(params.row)}
          label="Reject"
        />,
        <IsTenantCoordinator key={2}>
          <GridActionsCellItem
            icon={
              <Tooltip title={t('actions.pre-approve')} placement="top">
                <ThumbUpIcon color={params.row.status === 2 ? 'info' : 'disabled'} />
              </Tooltip>
            }
            onClick={() => preApprove(params.row)}
            label="Pre-pprove"
          />
        </IsTenantCoordinator>,
        <IsTenantAdministrator key={3}>
          <GridActionsCellItem
            icon={
              <Tooltip title={t('actions.approve')} placement="top">
                <VerifiedIcon color={params.row.status === 3 ? 'success' : 'disabled'} />
              </Tooltip>
            }
            onClick={() => approve(params.row)}
            label="Approve"
          />
        </IsTenantAdministrator>,
        params.row.id && (
          <>
            <GridActionsCellItem
              key={4}
              icon={
                <Tooltip title={t('actions.delete')} placement="top">
                  <BackspaceIcon />
                </Tooltip>
              }
              onClick={() => handleDelete(params.row)}
              label="remove"
            />
            <GridActionsCellItem
              key={5}
              label={t('actions.edit')}
              icon={<EditIcon />}
              onClick={() => handleEdit(params.row)}
            />
          </>
        )
      ]
    }
  ];
  return columns;
}
