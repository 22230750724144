import { GridCellEditCommitParams, MuiEvent } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import toast from 'features/toast';
import { t } from 'i18next';

export const onEditCellCallbackProvider =
  // FIXME: do not call service if value still the same
  (service: any, refresh?: any) => (params: GridCellEditCommitParams, event: MuiEvent) => {
    if (params.id) {
      const payload = {};
      payload[params.field] = params.value;
      if (params.value instanceof Date) {
        payload[params.field] = dayjs(params.value).format('YYYY-MM-DD');
      }
      console.log('params', params);
      service.update(params.id, { ...payload }).then(() => {
        toast.success(t('toasts.updated-cell'));
        if (refresh) refresh.current();
      });
    }
  };

export const booleanValueFormatter = ({ value }) => {
  try {
    return value ? 'SI' : 'NO';
  } catch {
    return '';
  }
};
