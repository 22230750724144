import { useParams } from 'react-router-dom';
import PurchaseRequestPage from '../components/PurchaseRequestPage';

export const PurchaseAutomaticRequest = () => {
  const { purchaseRequestId } = useParams();

  return (
    <PurchaseRequestPage
      purchaseRequestId={purchaseRequestId}
      automaticGenerated={true}
      translationPrefix="pages.purchase-requests.automatic"
    />
  );
};

export default PurchaseAutomaticRequest;
