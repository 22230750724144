import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { IOption, ITransportActiveDocument } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';
import { Button } from '@mui/material';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { useEffect, useState } from 'react';
import { orderService, warehouseService } from 'services/api';

interface TransportActiveDocumenFormParams {
  transport_document: ITransportActiveDocument;
  onSubmit: any;
  isUpdate: boolean;
}

export const TransportActiveDocumentForm = (params: TransportActiveDocumenFormParams) => {
  const { transport_document, onSubmit, isUpdate } = params;
  const { t } = useTranslation();

  const [orders, setOrders] = useState<IOption[]>([]);
  const [warehouses, setWarehouses] = useState<IOption[]>([]);
  const [selectedWarehouseOut, setSelectedWarehouseOut] = useState<string | null>(
    String(transport_document?.warehouse_out ?? '')
  );

  const fetchOrders = () => {
    const params = {
      // 2025-02-26: Introduzione dei Trasferimenti tra magazzini ORA OGNI ORDINE PUò ESSERE SCELTO come valido
      //              nascondo la condizione di visibilità per "codice magazzino"
      //warehouse: selectedWarehouseOut['code'] ?? '',
      status: 1
    };
    orderService.openOrders(1000, 0, params).then((res) => {
      setOrders(
        res.results.map((value) => {
          return {
            id: value.id,
            code: value.ref,
            description: value.description
          };
        })
      );
    });
  };

  useEffect(() => {
    if (selectedWarehouseOut) {
      fetchOrders();
    }
  }, [selectedWarehouseOut]);

  useEffect(() => {
    warehouseService.getAllBaseInformation().then(setWarehouses);
  }, []);

  return (
    <div>
      {transport_document && orders && (
        <ReactFinalForm
          onSubmit={onSubmit}
          edit={!!transport_document.id}
          hideControls={true}
          formId="amountForm">
          <div className="w-full">
            {/* Prima riga: Supplier e Warehouses (out e in) */}
            <div className="flex w-full gap-4 mb-4">
              <div className="flex-1">
                <IdentityField
                  name="warehouse_out"
                  label={t(`global.warehouse_out`)}
                  initialValue={transport_document.warehouse_out}
                  initValue={warehouses.find((s) => s.id === transport_document.warehouse_out)}
                  component={AutocompleteAdapter}
                  options={warehouses}
                  required
                  disabled={!!transport_document.warehouse_out}
                  type="text"
                  // this should controll the fetch of orders
                  setValue={setSelectedWarehouseOut}
                  service={warehouseService}
                  submitObject
                />
              </div>
              <div className="flex-1">
                <IdentityField
                  name="warehouse_in"
                  label={t(`global.warehouse_in`)}
                  initialValue={transport_document.warehouse_in}
                  initValue={warehouses.find((s) => s.id === transport_document.warehouse_in)}
                  component={AutocompleteAdapter}
                  options={warehouses}
                  required
                  disabled={!!transport_document.warehouse_in}
                  type="text"
                />
              </div>
              <div className="flex-1">
                <IdentityField
                  name="order"
                  label={t(`global.order`)}
                  initialValue={transport_document.order}
                  initValue={orders.find((o) => o.id === transport_document.order)}
                  component={AutocompleteAdapter}
                  options={orders}
                  optionLabel="description"
                  optionValue="id"
                  disabled={
                    !!transport_document.order ||
                    ((transport_document?.status === 'SENT' ||
                      transport_document?.status === 'ARRIVED') &&
                      isUpdate)
                  }
                  autoSelect={false}
                />
              </div>
            </div>

            {/* Seconda riga: Ref, Date */}
            <div className="flex w-full gap-4 mb-4 flex-wrap">
              <div className="flex-1">
                <IdentityField
                  name="ref"
                  label={t(`pages.active-ddt.ref`)}
                  initialValue={transport_document?.ref ?? ''}
                  disabled={
                    (transport_document?.status === 'SENT' ||
                      transport_document?.status === 'ARRIVED') &&
                    isUpdate
                  }
                  component={TextFieldAdapter}
                  type="text"
                />
              </div>
              <div className="flex-1">
                <IdentityField
                  name="date"
                  label={t(`pages.active-ddt.date`)}
                  component={DatePickerAdapter}
                  type="date"
                  disabled={
                    (transport_document?.status === 'SENT' ||
                      transport_document?.status === 'ARRIVED') &&
                    isUpdate
                  }
                  initialValue={transport_document?.date ?? null}
                  noFuture={true}
                />
              </div>
            </div>

            {/* Terza riga: Peso totale & Colli N. */}
            <div className="flex w-full gap-4 mb-4">
              <div className="flex-1">
                <IdentityField
                  name="colli"
                  label={t(`pages.active-ddt.colli`)}
                  initialValue={transport_document?.colli ?? ''}
                  disabled={
                    (transport_document?.status === 'SENT' ||
                      transport_document?.status === 'ARRIVED') &&
                    isUpdate
                  }
                  component={TextFieldAdapter}
                  type="text"
                />
              </div>
              <div className="flex-1">
                <IdentityField
                  name="weight"
                  label={t(`pages.active-ddt.weight`)}
                  initialValue={transport_document?.weight ?? ''}
                  disabled={
                    (transport_document?.status === 'SENT' ||
                      transport_document?.status === 'ARRIVED') &&
                    isUpdate
                  }
                  component={TextFieldAdapter}
                  type="text"
                />
              </div>
            </div>

            {/* Quarta riga: Note */}
            <div className="flex w-full gap-4 mb-4">
              <div className="flex-1 w-full">
                <IdentityField
                  name="notes"
                  label={t(`global.notes`)}
                  initialValue={transport_document?.notes ?? ''}
                  disabled={
                    (transport_document?.status === 'SENT' ||
                      transport_document?.status === 'ARRIVED') &&
                    isUpdate
                  }
                  component={TextFieldAdapter}
                  type="text"
                />
              </div>
            </div>

            {/* Quinta riga: Pulsante Save */}
            <div className="flex justify-end">
              <Button variant="contained" type="submit" form="amountForm" color="primary">
                {t(`global.save`)}
              </Button>
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
};

export default TransportActiveDocumentForm;
