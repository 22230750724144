import { ButtonGroup, Button, Chip } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import { IMovementReason } from 'interfaces';
import { movementReasonService } from 'services/api/MovementReasonService';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import TenantPage from 'components/common/TenantPage';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link } from 'react-router-dom';
import DataGridCell from 'components/common/DataGridCell';
import { booleanCell } from 'components/common/enhanced/cells/boolean-cell';
import ConfirmationDeleteTableDialog from 'components/helpers/ConfirmationGridDelete';
import toast from 'features/toast';
import { useRef } from 'react';

export const MovementReasons = () => {
  const translationPrefix = 'pages.reason.list';
  const { t } = useTranslation();
  const datagridRefresh = useRef(null);

  const handleOnDelete = (id: number) => {
    movementReasonService.delete(id).then((res) => {
      if (res) {
        toast.success(t('actions.successfully.deleted'));
      }

      datagridRefresh.current();
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: t(`global.code`),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const movementReason = params.row;
        return (
          <div>
            <Link to={`${movementReason.id}`}>
              <b className="text-secondary-500">{movementReason.code}</b>
            </Link>
          </div>
        );
      }
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const description = params.row.description;
        const parts = description ? description.split(' - ') : [];
        const label = parts.length > 1 ? parts[1] : description;

        return <div>{label}</div>;
      }
    },
    {
      field: 'direction',
      headerName: t(`entities.movement.direction`),
      flex: 1,
      renderCell(params) {
        const reason = params.row as IMovementReason;
        return reason.direction.toLocaleLowerCase() === 'in' ? (
          <Chip label={<ArrowDownwardIcon />} variant="outlined" color="success" />
        ) : (
          <Chip label={<ArrowUpward />} variant="outlined" color="error" />
        );
      }
    },
    {
      field: 'discounted',
      headerName: t(`entities.movement.discounted`),
      flex: 1,
      renderCell: booleanCell(t)
    },
    {
      field: 'order_independent',
      headerName: t(`entities.movement.order_independent`),
      flex: 1,
      renderCell: booleanCell(t)
    },
    {
      field: 'ddt',
      headerName: t(`entities.movement.ddt`),
      flex: 1,
      renderCell: booleanCell(t)
    },
    {
      field: 'ddt_active',
      headerName: t(`entities.movement.ddt_active`),
      flex: 1,
      renderCell: booleanCell(t)
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <ConfirmationDeleteTableDialog
            key={996}
            id={+params.row.id}
            handleDelete={handleOnDelete}
          />
        );
      },
      filterable: false
    }
  ];

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div>
          <ButtonGroup>
            <IsTenantAdministrator>
              <Link to="new">
                <Button color="primary" size="small">
                  <AddIcon />
                  {t(`${translationPrefix}.add`)}
                </Button>
              </Link>
            </IsTenantAdministrator>
          </ButtonGroup>
        </div>
      }>
      <div className="mt-6">
        <EnhancedDataGrid
          columns={columns}
          service={movementReasonService}
          refresh={datagridRefresh}
        />
      </div>
    </TenantPage>
  );
};

export default MovementReasons;
