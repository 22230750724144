import ReactFinalForm from 'components/form/ReactFinalForm';
import { IOption, IPurchaseRequestRow } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { required } from 'helpers/validators';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';

interface ContractPurchaseRequestLineParams {
  purchaseRequestLine: IPurchaseRequestRow;
  contracts: IOption[];
  onSubmit: any;
}

function EditAutomaticPurchaseRequestLine(params: ContractPurchaseRequestLineParams) {
  const { t } = useTranslation();
  const { purchaseRequestLine, contracts, onSubmit } = params;

  return (
    <div>
      <div className="align-middle p-2">
        <ReactFinalForm onSubmit={onSubmit} formId="editPurchaseRequestLine" hideControls>
          <div className="p-2 ">
            <IdentityField
              name="contract"
              label={t('global.contract')}
              component={AutocompleteAdapter}
              options={contracts}
              type="text"
              optionValue="code"
              initValue={
                purchaseRequestLine.contract
                  ? contracts.find((c) => c.code === purchaseRequestLine.contract.toString())
                  : null
              }
              defaultValue={purchaseRequestLine.contract}
              validate={required}
            />
          </div>
          <div className="p-2 ">
            <IdentityField
              name="quantity"
              label={t('global.quantity')}
              component={TextFieldAdapter}
              type="number"
              defaultValue={purchaseRequestLine.quantity}
              validate={required}
            />
          </div>
          <div className="p-2 ">
            <IdentityField
              name="requested_shipment_date"
              component={DatePickerAdapter}
              label={t(`entities.purchase_request.requested_shipment_date`)}
              defaultValue={purchaseRequestLine.requested_shipment_date}
            />
          </div>
          <div className="p-2 ">
            <IdentityField
              name="notes"
              label={t(`global.notes`)}
              initialValue={purchaseRequestLine.notes}
              component={TextFieldAdapter}
            />
          </div>
        </ReactFinalForm>
      </div>
    </div>
  );
}

export default EditAutomaticPurchaseRequestLine;
