import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditAutomaticPurchaseRequestLine from './EditAutomaticPurchaseRequestLine';
import EditPurchaseRequestLine from './EditPurchaseRequestLine';
import { IPurchaseRequestRow } from 'interfaces';

interface EditDialogProps {
  open: boolean;
  onClose: () => void;
  editPurchaseRequestLine: IPurchaseRequestRow;
  automaticGenerated: boolean;
  onSubmitEdit: (data: any) => void;
  editContractOptions?: any[];
}

const EditDialog: React.FC<EditDialogProps> = ({
  open,
  onClose,
  editPurchaseRequestLine,
  automaticGenerated,
  onSubmitEdit,
  editContractOptions
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle id="alert-dialog-title">{t('pages.movements.edit')}</DialogTitle>
      <DialogContent>
        {automaticGenerated ? (
          <EditAutomaticPurchaseRequestLine
            purchaseRequestLine={editPurchaseRequestLine}
            contracts={editContractOptions}
            onSubmit={onSubmitEdit}
          />
        ) : (
          <EditPurchaseRequestLine
            purchaseRequestLine={editPurchaseRequestLine}
            onSubmit={onSubmitEdit}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          {t('actions.cancel')}
        </Button>
        <Button type="submit" form="editPurchaseRequestLine">
          {t('actions.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
