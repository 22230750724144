import TenantPage from 'components/common/TenantPage';
import { useTranslation } from 'react-i18next';
import toast from 'features/toast';
import { medicalServiceEtlFieldService } from 'services/api/MedicalServiceEtlFieldService';
import MedicalServiceEtlFieldsForm from '../MedicalServiceEtlFieldForm';

const initTranscode = {
  id: null,
  field_from: null,
  field_to: null,
  transcode: null
};

export const CreateMedicalServiceEtlFields = () => {
  const translationPrefix = 'pages.extra-field-transcode.new';

  const { t } = useTranslation();

  const createTranscode = async (values) => {
    medicalServiceEtlFieldService.add(values).then((res) => {
      if (res) toast.success('Transcodifica aggiunta con successo.');
    });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <MedicalServiceEtlFieldsForm transcode={initTranscode} onSubmit={createTranscode} />
    </TenantPage>
  );
};

export default CreateMedicalServiceEtlFields;
