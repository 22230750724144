import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {
  DataGridPremium,
  GridCellEditCommitParams,
  GridColumns,
  itIT,
  MuiEvent
} from '@mui/x-data-grid-premium';
import { v4 as uuidv4 } from 'uuid';
import SendOrderDialog from './SendOrderDialog';
import PurchaseRequestAccordion from './PurchaseRequestAccordion';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import ExportButtonToolbar from '../ExportButtonToolbar';
import {
  IOption,
  IProductWarehouse,
  IPurchaseOrderRow,
  IPurchaseRequest,
  IPurchaseRequestRow,
  IStockQuantity,
  ISupplier,
  IWarehouse
} from 'interfaces';
import CustomizedSteppers from 'components/common/RequestStatus';
import ConfirmationDialog from './ConfirmDialog';
import { IsTenantCoordinator } from 'components/helpers/IsTenantCoordinator';
import {
  contractLineService,
  movementService,
  orderLineService,
  productService,
  productWarehouseService,
  purchaseRequestLineService,
  purchaseRequestService,
  stockQuantityService
} from 'services/api';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import toast from 'features/toast';
import { useSnackbar } from 'notistack';
import { PurchaseRequestStatus } from '../../../../constants';
import dayjs from 'dayjs';
import { PurchaseLinesColumns } from 'components/common/enhanced/common-headers/request-lines';
import EditDialog from './EditDialog';
import { AutomaticPurchaseLinesColumns } from 'components/common/enhanced/common-headers/automatic-request-lines';

interface PurchaseRequestPageProps {
  purchaseRequestId: string;
  automaticGenerated: boolean;
  translationPrefix: string;
}

const PurchaseRequestPage: React.FC<PurchaseRequestPageProps> = ({
  purchaseRequestId,
  automaticGenerated,
  translationPrefix
}) => {
  const { t } = useTranslation();
  if (purchaseRequestId) {
    translationPrefix += '.view';
  } else {
    translationPrefix += '.new';
  }
  const toastTranlationPrefix = 'pages.purchase-requests.toast';

  // ------------------- USE STATES -------------------
  const [productToAdd, setProductToAdd] = useState<any>(null);
  const [contractToAdd, setContractToAdd] = useState<any>(null);
  const [warehouseToAdd, setWarehouseToAdd] = useState<any>(null);
  const [maxUnits, setMaxUnits] = useState<number | null>(null);
  const [maxDiscountedUnits, setMaxDiscountedUnits] = useState<number | null>(null);
  const [productOptions, setProductOptions] = useState<IOption[]>([]);
  const [contractOptions, setContractOptions] = useState<IOption[]>([]);
  const [warehouseOptions, setWarehouseOptions] = useState<IOption[]>([]);
  const [purchaseRequest, setPurchaseRequest] = useState<IPurchaseRequest>({
    lines: [],
    id: null,
    status: null,
    created_by: 0
  });
  const [initialRequest, setInitialRequest] = useState<IPurchaseRequest>(purchaseRequest);
  const [visibleProduct, setVisibleProduct] = useState<{
    code: string;
    description?: string;
    stockQuantities?: IStockQuantity[];
    productWarehouse?: IProductWarehouse[];
    orderLines?: IPurchaseOrderRow[];
    consume?: any[];
    unitPrice?: string;
  } | null>(null);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [opendConfirmationDialog, setOpendConfirmationDialog] = useState(false);
  const [confirmationValues, setConfirmationValues] = useState(null);
  const [requestPreview, setRequestPreview] = useState();
  const [unitsPerPackage, setUnitsPerPackage] = useState<number>(0);
  const [unitPriceInfo, setUnitPriceInfo] = useState<string>(null);
  const [editContractOptions, setEditContractOptions] = useState<IOption[]>([]);
  const [openEditContractDialog, setOpenEditContractDialog] = useState(false);
  const [editPurchaseRequestLine, setEditPurchaseRequestLine] = useState<any>(null);

  const changesToSave = (): boolean => {
    return JSON.stringify(initialRequest) === JSON.stringify(purchaseRequest);
  };
  const allLinesSelected = (): boolean => {
    return !purchaseRequest.lines.some(
      ({ status }) =>
        status === PurchaseRequestStatus.NEW || status === PurchaseRequestStatus.PRE_APPROVED
    );
  };
  const CustomToolbar = () => <ExportButtonToolbar purchaseRequestId={purchaseRequest.id} />;
  const { enqueueSnackbar } = useSnackbar();

  // ------------------- USE EFFECT -------------------

  // Constructor
  useEffect(() => {
    productService.getAllBaseInformation({ status: 'ACTIVE' }).then(setProductOptions);
    if (purchaseRequestId) {
      purchaseRequestService.get(+purchaseRequestId).then((pr) => {
        setPurchaseRequest(pr);
        setInitialRequest(pr);
      });
    }
  }, []);

  useEffect(() => {
    if (productToAdd) {
      setContractToAdd(null);
      setContractOptions([]);
      contractLineService
        .getAllBaseInformation({ product_id: productToAdd.id, active: true, valid: true })
        .then(setContractOptions);
    } else {
      setContractToAdd(null);
      setContractOptions([]);
      setWarehouseToAdd(null);
      setWarehouseOptions([]);
    }
  }, [productToAdd]);

  useEffect(() => {
    if (contractToAdd?.id) {
      contractLineService.getSummary(contractToAdd.id).then((res) => {
        setMaxUnits(res.available_units_not_blocked);
        setMaxDiscountedUnits(res.available_discounted_units_not_blocked);
        setWarehouseOptions(res.warehouses);
        // BOX INFO - Get the unit price (1)
        setUnitPriceInfo(res.unit_price);
      });
    } else {
      setMaxUnits(null);
      setMaxDiscountedUnits(null);
      setWarehouseToAdd(null);
      setWarehouseOptions([]);
    }
  }, [contractToAdd]);

  useEffect(() => {
    if (warehouseToAdd && productToAdd && contractToAdd) {
      const limit = 20;
      const offset = 0;

      // Fetch product warehouse information
      const fetchProductWarehouseInfo = productWarehouseService.getAllPaginated(limit, offset, {
        product_id: productToAdd.id,
        warehouse_id: warehouseToAdd.id
      });

      // Fetch stock quantities information
      const fetchStockQuantitiesInfo = stockQuantityService.getAllPaginated(limit * limit, offset, {
        product__id: productToAdd.id,
        warehouse__id: warehouseToAdd.id
      });

      // Fetch product order lines information
      const fetchProductOrderLinesInfo = orderLineService.getAllPaginated(limit, offset, {
        product_id: productToAdd.id,
        warehouse_id: warehouseToAdd.id,
        status: 'PENDING'
      });

      // Fetch product consumption information for the past year
      const paramsConsumption = {
        timestamp__gte: dayjs().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'),
        timestamp__lte: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
        product__id: productToAdd.id,
        warehouse__id: warehouseToAdd.id
      };
      const fetchProductConsumeInfo = movementService.consumptionDetail(
        limit,
        offset,
        paramsConsumption
      );

      // Execute all fetch requests concurrently and update the state with the results
      Promise.all([
        fetchProductWarehouseInfo,
        fetchStockQuantitiesInfo,
        fetchProductOrderLinesInfo,
        fetchProductConsumeInfo
      ]).then(
        ([productWarehouseRes, stockQuantitiesRes, productOrderLinesRes, productConsumeRes]) => {
          setVisibleProduct({
            code: productToAdd.code,
            description: productToAdd.description.replace(
              //correzione descrizion IOption
              productToAdd.code + ' - ',
              ''
            ),
            stockQuantities: stockQuantitiesRes.results,
            productWarehouse: productWarehouseRes.results,
            orderLines: productOrderLinesRes.results,
            consume: productConsumeRes.results,
            unitPrice: unitPriceInfo
          });
          setUnitsPerPackage(parseInt(productWarehouseRes?.results[0]?.units_per_package) || 0);
        }
      );
    } else {
      // Reset visible product information if any of the dependencies change
      setVisibleProduct(null);
    }
  }, [warehouseToAdd, productToAdd, contractToAdd, unitPriceInfo]);

  // ------------------- Request Action -------------------
  const saveRequest = () => {
    // Check if the purchase request is valid
    if (!purchaseRequest) {
      toast.error(t(`${toastTranlationPrefix}.invalid-request`));
      return;
    }

    // Map the lines in the purchase request
    const lines = purchaseRequest.lines.map(mapLine);

    // Create the request object
    const request = {
      ...purchaseRequest,
      lines
    };

    // Determine the service promise
    const servicePromise = purchaseRequest.id
      ? // Update the purchase request
        purchaseRequestService.update(purchaseRequest.id, request)
      : // Add the purchase request
        purchaseRequestService.add(request);

    // Call the service promise
    servicePromise
      .then((res) => {
        if (res?.id) {
          // Update the purchase request state
          setPurchaseRequest(res);

          // Update the initial request state
          setInitialRequest(res);

          // Determine the message based on the purchase request id
          const message = purchaseRequest?.id
            ? t(`${toastTranlationPrefix}.line-successfully-updated`)
            : t(`${toastTranlationPrefix}.line-successfully-added`);

          // Show the success snackbar
          enqueueSnackbar(message, { variant: 'success' });
        }
      })
      .catch((e) => {
        // Show the error toast
        toast.error(e.message);
      });
  };

  // Edit Purchase req methods
  const reviewRequest = () => {
    if (purchaseRequest.id) {
      purchaseRequestService
        .preApproveRequest({
          id: purchaseRequest?.id,
          lines: purchaseRequest.lines.map(mapLine)
        })
        .then(() => {
          enqueueSnackbar(t(`${toastTranlationPrefix}.pre-approved-successfully`), {
            variant: 'success'
          });
          location.reload();
        })
        .catch(() => {
          enqueueSnackbar(t(`${toastTranlationPrefix}.no-permission`), { variant: 'error' });
        });
    }
  };

  const fulfillRequest = (sendEmail = false) => {
    if (purchaseRequestId) {
      if (purchaseRequest.lines.some((e) => e.status === 1)) {
        setOpenPreviewDialog(false);
        toast.error(t(`${toastTranlationPrefix}.pending-lines`));
        return;
      }
      purchaseRequestService
        .approveRequest(
          {
            id: +purchaseRequestId,
            lines: purchaseRequest.lines.map(mapLine)
          },
          sendEmail
        )
        .then(() => {
          location.reload();
          enqueueSnackbar(t(`${toastTranlationPrefix}.order-sent-successfully`), {
            variant: 'success'
          });
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }
  };

  const onEditCell = (params: GridCellEditCommitParams, event: MuiEvent) => {
    if (+params.id) {
      const payload = {};
      if (params.field == '_quantity') {
        payload['quantity'] = params.value;
      } else {
        payload[params.field] = params.value;
      }
      if (payload[params.field])
        purchaseRequestLineService.update(+params.id, { ...payload }).then(() => {
          toast.success(t('toasts.updated-cell'));
        });
      //update prices value by unit_price
      if (params.field == 'static_unit_price') {
        const updatedLines = purchaseRequest.lines.map((line) => {
          if (line.id === params.id) {
            line['total_price'] = Math.round(params.value * line['quantity']);
            line['static_unit_price'] = params.value;
          }
          return line;
        });
        setPurchaseRequest({ ...purchaseRequest, lines: updatedLines });
      }
      //update total price value by quantity
      if (params.field == 'quantity') {
        const updatedLines = purchaseRequest.lines.map((line) => {
          if (line.id === params.id) {
            line['total_price'] = params.value * line['static_unit_price'];
            line['quantity'] = params.value;
          }
          return line;
        });
        setPurchaseRequest({ ...purchaseRequest, lines: updatedLines });
      }
    } else {
      setPurchaseRequest({
        ...purchaseRequest,
        lines: purchaseRequest.lines.map((line) => {
          if (line.id === params.id) {
            if (params.value instanceof Date) {
              line[params.field] = dayjs(params.value).format('YYYY-MM-DD');
            } else {
              if (params.field == '_quantity') {
                line['quantity'] = params.value;
              } else {
                line[params.field] = params.value;
              }
            }
          }
          return line;
        })
      });
    }
  };

  // ------------------- SUBMIT - SINGLE && MULTI ROW -------------------
  const onSubmitRow = (values) => {
    // ERRORE ! veniva perso il contract code siccome il "setContract" ora gestisce la contract_line
    // .....
    const matchingContract = values.contracts.find(
      (contract) => contract.id === values.contract_line
    );
    if (matchingContract) {
      values.contract = matchingContract.code;
    }

    if (parseInt(values?.quantity || 0) === 0 && parseInt(values?.discounted_quantity || 0) === 0) {
      toast.error(t(`${toastTranlationPrefix}.quantity-error`));
      return;
    }

    // Access the TENANT field from the .env file
    const tenant = process.env.REACT_APP_TENANT;
    // MESTRE: per mestre, crema e molise il controllo per quantità multiple NON è RICHIESTO
    // Check if the quantity per units of package is valid
    if (
      tenant !== 'MAIN' &&
      unitsPerPackage &&
      (values.quantity || values.discounted_quantity) &&
      (parseInt(values?.quantity || 0) + parseInt(values?.discounted_quantity || 0)) %
        unitsPerPackage !==
        0
    ) {
      handleOpenConfirmationDialog(values);
    } else {
      onSubmitRowPostQuantityPerPacketCheck(values);
    }
  };

  const onSubmitRowPostQuantityPerPacketCheck = (values: any | null) => {
    // Check if the contract and warehouse are valid
    let errors = false;
    if (!contractOptions.map((c) => c.id).includes(values['contract_line'])) {
      toast.error(t(`${toastTranlationPrefix}.contract-error`));
      errors = true;
    }
    if (!warehouseOptions.map((c) => c.code).includes(values['warehouse'])) {
      toast.error(t(`${toastTranlationPrefix}.warehouse-error`));
      errors = true;
    }
    if (errors) return;
    // MULTIPLE ROWS
    if (values.programmed) {
      const startDate = dayjs(values.start_programmed);
      let currentDate = startDate,
        lastValidDay,
        lastValidDayOfWeek,
        lastValidWeek = 0,
        lastValidMonth;
      const endDate = dayjs(values.end_programmed);
      do {
        lastValidDay = currentDate.format('DD');
        lastValidDayOfWeek = currentDate.day();
        lastValidWeek += lastValidDayOfWeek === startDate.day() ? 1 : 0;
        lastValidMonth = currentDate.month();

        if (
          (values.rep_unit === 1 &&
            lastValidWeek % values.rep_frequency === 0 &&
            values.day_of_month == +lastValidDayOfWeek) ||
          (values.rep_unit === 2 &&
            (lastValidMonth - startDate.month()) % values.rep_frequency === 0 &&
            +values.day_of_month === +lastValidDay)
        ) {
          ((requested_shipment_date) => {
            _settingPurcheaseRequestSingleRow(values, requested_shipment_date);
          })(currentDate.format('YYYY-MM-DD'));
        }

        currentDate = currentDate.add(1, 'day');
      } while (currentDate.isBefore(endDate));
    } else {
      // SINGLE ROW
      _settingPurcheaseRequestSingleRow(values, null);
    }
  };

  function _settingPurcheaseRequestSingleRow(values: any, requested_shipment_date: any) {
    setPurchaseRequest((prevState) => {
      const newLines = [];
      // if the request is periodic
      if (values.programmed) {
        // check if the order is standard
        if (values.quantity > 0) {
          newLines.push({
            id: uuidv4(),
            ...values,
            requested_shipment_date,
            static_unit_price: unitPriceInfo,
            total_price: parseFloat(unitPriceInfo) * values.quantity,
            is_periodic: true,
            status: 1,
            index: prevState?.lines?.length + newLines.length + 1
          });
        }
        //Check if the order is discounted
        if (values.discounted_quantity > 0) {
          newLines.push({
            id: uuidv4(),
            ...values,
            discounted: true,
            requested_shipment_date,
            static_unit_price: unitPriceInfo,
            total_price: 0, // chiedere se è parseFloat(unitPriceInfo) * values.discounted_quantity,
            quantity: values.discounted_quantity,
            is_periodic: true,
            status: 1,
            index: prevState?.lines?.length + newLines.length + 1
          });
        }
      } else {
        // check if the order is standard
        if (values.quantity > 0) {
          newLines.push({
            id: uuidv4(),
            ...values,
            status: 1,
            static_unit_price: unitPriceInfo,
            total_price: parseFloat(unitPriceInfo) * values.quantity,
            index: prevState?.lines?.length + newLines.length + 1
          });
        }
        //Check if the order is discounted
        if (values.discounted_quantity > 0) {
          newLines.push({
            id: uuidv4(),
            ...values,
            discounted: true,
            static_unit_price: unitPriceInfo,
            total_price: 0, // chiedere se è parseFloat(unitPriceInfo) * values.discounted_quantity,
            quantity: values.discounted_quantity,
            status: 1,
            index: prevState?.lines?.length + newLines.length + 1
          });
        }
      }

      return {
        ...prevState,
        lines: prevState.lines.concat(newLines)
      };
    });
  }

  // ------------------- ORDER PREVIEW -------------------
  const getOrderPreview = () => {
    if (!allLinesSelected()) {
      toast.error(t(`${toastTranlationPrefix}.lines-error`));
      return;
    }
    if (purchaseRequestId) {
      purchaseRequestService
        .getOrderPreview({ ...purchaseRequest, id: +purchaseRequestId })
        .then((res) => {
          setRequestPreview(res);
          setOpenPreviewDialog(true);
        });
    }
  };

  const getOrderPreviewInnerContent = () => {
    return (
      requestPreview &&
      Object.keys(requestPreview)
        .filter((k) => k !== 'detail')
        .map((v, i) => {
          // Supplier
          const supplier = requestPreview[v];
          const supplierDetail = requestPreview[v]['detail'] as ISupplier;
          return (
            <div key={v + i} className="px-3 mb-3">
              <b>{supplierDetail['company_name']}</b>
              <ul>
                {Object.keys(supplier)
                  .filter((k) => k !== 'detail')
                  .map((k) => {
                    const warehouse = requestPreview[v][k];
                    const warehouseDetail = requestPreview[v][k]['detail'] as IWarehouse;

                    const lines = warehouse['lines'] as IPurchaseRequestRow[];

                    return (
                      <div key={warehouseDetail.id} className="px-3">
                        {warehouseDetail.description}
                        <ul className="px-3">
                          {lines.map((line) => (
                            <li key={warehouse['id'] + '-' + line.id}>
                              <b>{line.product}</b> - {line.quantity}
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
              </ul>
            </div>
          );
        })
    );
  };

  const handleClosePreviewDialog = () => {
    setOpenPreviewDialog(false);
  };

  const handleCloseConfirmationDialog = () => {
    setOpendConfirmationDialog(false);
  };

  const handleSubmitConfirmation = () => {
    onSubmitRowPostQuantityPerPacketCheck(confirmationValues);
    setConfirmationValues(null);
    setOpendConfirmationDialog(false);
  };

  const handleOpenConfirmationDialog = (values) => {
    setOpendConfirmationDialog(true);
    setConfirmationValues(values);
  };

  // utility function
  function mapLine(item) {
    return {
      ...item,
      id: +item.id || null,
      product: item.product['code']
    };
  }

  // // Utility methods
  const handleDelete = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);
    setPurchaseRequest((prevState) => {
      const lines = prevState.lines;
      const newLines = lines.map((obj, idx) => {
        if (index === idx) {
          return { ...obj, status: PurchaseRequestStatus.DELETED };
        }
        return obj;
      });
      return { ...prevState, lines: newLines };
    });
  };

  const approve = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);

    changeRowStatus(index, PurchaseRequestStatus.APPROVED);
  };

  const preApprove = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);

    changeRowStatus(index, PurchaseRequestStatus.PRE_APPROVED);
  };

  const reject = (line) => {
    const index = purchaseRequest.lines.findIndex((l) => l.id === line.id);

    changeRowStatus(index, PurchaseRequestStatus.REJECTED);
  };

  const changeRowStatus = (index, newStatus) => {
    setPurchaseRequest((prevState) => {
      const lines = prevState.lines;
      const newLines = lines.map((obj, idx) => {
        return index === idx
          ? {
              ...obj,
              status: newStatus
            }
          : obj;
      });
      return { ...prevState, lines: newLines };
    });
  };

  // --------------------  Dialog  edit purchase req line --------------------
  const setOpenEditDialog = (row: any) => {
    setEditPurchaseRequestLine(row); // Set the current row data to be edited
    if (row.product) {
      contractLineService
        .getAllBaseInformation({ product_id: row.product.id, active: true, valid: true })
        .then(setEditContractOptions);
    }
    setOpenEditContractDialog(true); // Open the edit dialog
  };

  const handleCloseEditDialog = () => {
    setOpenEditContractDialog(false);
  };

  const onSubmitEdit = async (values) => {
    const lineIndex = purchaseRequest.lines.findIndex(
      (line) => line.id === editPurchaseRequestLine.id
    );
    const updatedLines = [...purchaseRequest.lines];

    updatedLines[lineIndex] = {
      ...updatedLines[lineIndex],
      quantity: values.quantity !== undefined ? values.quantity : updatedLines[lineIndex].quantity,
      static_unit_price:
        values.static_unit_price !== undefined
          ? values.static_unit_price
          : updatedLines[lineIndex].static_unit_price,
      requested_shipment_date:
        values.requested_shipment_date !== undefined
          ? values.requested_shipment_date
          : updatedLines[lineIndex].requested_shipment_date,
      notes: values.notes !== undefined ? values.notes : updatedLines[lineIndex].notes,
      contract: values.contract !== undefined ? values.contract : updatedLines[lineIndex].contract
    };
    setPurchaseRequest({
      ...purchaseRequest,
      lines: updatedLines
    });
    setOpenEditContractDialog(false);
  };

  let columns: GridColumns = [];
  if (automaticGenerated) {
    columns = AutomaticPurchaseLinesColumns(
      reject,
      approve,
      preApprove,
      handleDelete,
      setOpenEditDialog
    );
  } else {
    columns = PurchaseLinesColumns(reject, approve, preApprove, handleDelete, setOpenEditDialog);
  }

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`, { id: purchaseRequest['id'] })}
      subtitle={t(`${translationPrefix}.subtitle`)}
      menuRight={
        <div className="flex items-center justify-end">
          <div className="w-60">
            <CustomizedSteppers status={purchaseRequest.status} />
          </div>
          {purchaseRequest.status !== 'ORDER_SENT' && (
            <ButtonGroup size="small" variant="outlined">
              <Button disabled={changesToSave()} onClick={saveRequest}>
                <Tooltip title={t('global.save')}>
                  <SaveIcon />
                </Tooltip>
              </Button>
              <IsTenantCoordinator>
                <Button disabled={!changesToSave()} onClick={reviewRequest}>
                  {t(`${translationPrefix}.pre-approve`)}
                </Button>
              </IsTenantCoordinator>
              {purchaseRequest.id && (
                <IsTenantAdministrator>
                  <Button
                    disabled={!changesToSave()}
                    color={allLinesSelected() ? 'success' : 'warning'}
                    onClick={getOrderPreview}>
                    {t(`pages.purchase-requests.generate-order`)}
                  </Button>
                </IsTenantAdministrator>
              )}
            </ButtonGroup>
          )}
        </div>
      }>
      {/* Form per la richiesta di acquisto */}
      <PurchaseRequestAccordion
        productOptions={productOptions}
        contractOptions={contractOptions}
        warehouseOptions={warehouseOptions}
        maxUnits={maxUnits}
        maxDiscountedUnits={maxDiscountedUnits}
        visibleProduct={visibleProduct}
        onSubmitRow={onSubmitRow}
        setProductToAdd={setProductToAdd}
        setContractToAdd={setContractToAdd}
        setWarehouseToAdd={setWarehouseToAdd}
      />

      {/* Item list */}
      <div>
        <DataGridPremium
          density="compact"
          initialState={{
            pinnedColumns: {
              left: ['code', 'approved'],
              right: ['actions']
            }
          }}
          rows={purchaseRequest.lines
            .filter((row) => row.status !== PurchaseRequestStatus.DELETED)
            .map((l) => {
              return {
                id: uuidv4(),
                ...l,
                description: l.product['description']
              };
            })}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          components={{
            Toolbar: CustomToolbar
          }}
          sx={{ border: 'none' }}
          autoHeight
          disableSelectionOnClick
          pagination
          columns={columns}
          onCellEditCommit={onEditCell}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
        />
      </div>

      {/* Edit  dialog */}
      <div>
        <EditDialog
          open={openEditContractDialog}
          onClose={handleCloseEditDialog}
          editPurchaseRequestLine={editPurchaseRequestLine}
          automaticGenerated={automaticGenerated}
          onSubmitEdit={onSubmitEdit}
          editContractOptions={editContractOptions}
        />
      </div>

      {/* Send order */}
      <div>
        <SendOrderDialog
          open={openPreviewDialog}
          onClose={handleClosePreviewDialog}
          fulfillRequest={fulfillRequest}
          getOrderPreviewInnerContent={getOrderPreviewInnerContent}
        />
      </div>

      {/* Confirmation dialog */}
      <div>
        <ConfirmationDialog
          open={opendConfirmationDialog}
          onClose={handleCloseConfirmationDialog}
          onConfirm={handleSubmitConfirmation}
        />
      </div>
    </TenantPage>
  );
};

export default PurchaseRequestPage;
