import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { IMovement } from 'interfaces';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import {
  filterOperators,
  dateFilterOperators,
  numberFilterOperators,
  booleanFilterOperators
} from '../../../../constants';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { createSearchParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { baseCell } from '../cells/base-cell';
import { currencyCell } from '../cells/currency-cell';
import productStatusCell from '../cells/product-status-cell';
import { printLabel } from 'services/zebra';
import { booleanCell } from '../cells/boolean-cell';
import { dateCell } from '../cells/date-cell';
import { currencyFormatter } from 'helpers/currency-formatter';
import dayjs from 'dayjs';
import sapStateCell from '../cells/sap-state-cell';
import { transportDocumentService } from 'services/api';

export function MovementsColumns(
  types: string[],
  canValidate = false,
  canEdit = false,
  detail = false,
  setMovement?: Dispatch<SetStateAction<number>>,
  editableExpiration = false,
  sap = false
): GridColDef[] {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const translationPrefix = 'entities.movement';
  const [closed, setClosed] = useState<boolean>(false);

  const movementsDetail = (params) => {
    const filteredParams = _.omitBy(params, _.isEmpty);
    filteredParams['discounted'] = params['discounted'];
    navigate({
      pathname: '/tenant/movements/detail/',
      search: `${createSearchParams(filteredParams)}`
    });
  };

  const columns: GridColumns | any[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: t(`global.actions`),
      getActions: (params: GridRowParams) => {
        {
          const movementRow = params.row;
          if (detail) {
            if (movementRow.id) {
              const actions = [
                <GridActionsCellItem
                  key={1}
                  icon={<LocalPrintshopIcon />}
                  onClick={() =>
                    printLabel(
                      params.row['product'],
                      params.row['product_batch'],
                      params.row['expiration_date'],
                      params.row['description']
                    )
                  }
                  label={t('global.print_labels')}
                />,
                <GridActionsCellItem
                  key={2}
                  icon={<UnfoldLessIcon />}
                  onClick={() =>
                    printLabel(
                      params.row['product'],
                      params.row['product_batch'],
                      params.row['expiration_date'],
                      params.row['description'],
                      true
                    )
                  }
                  label={t('global.print_barcodes')}
                />
              ];

              useEffect(() => {
                if (movementRow.transport_document) {
                  transportDocumentService.get(movementRow.transport_document).then((res) => {
                    setClosed(res.closed);
                  });
                }
              }, [movementRow.transport_document]);

              if (!closed) {
                actions.push(
                  <GridActionsCellItem
                    key={3}
                    icon={<DeleteIcon />}
                    onClick={() => {
                      setMovement(+params.row.id);
                    }}
                    label={t('global.delete')}
                  />
                );
              }
              return actions;
            } else {
              return [];
            }
          } else {
            return [
              <GridActionsCellItem
                key={2}
                label="Dettaglio"
                icon={<ManageSearchIcon />}
                onClick={() =>
                  movementsDetail({
                    product__id: `${movementRow.product__id}`,
                    warehouse: movementRow.warehouse,
                    type: movementRow.type,
                    created_by: movementRow.created_by,
                    product_batch: movementRow.product_batch,
                    purchase_order: movementRow.purchase_order,
                    timestamp: movementRow.timestamp,
                    discounted: movementRow.discounted
                  })
                }
              />
            ];
          }
        }
      },
      filterable: false
    },
    {
      field: 'moved_quantity',
      sortable: false,
      filterOperators,
      headerName: t(`${translationPrefix}.moved_quantity`),
      type: 'number',
      renderCell: (params: GridRenderCellParams<string>) => {
        const movement = params.row as IMovement;
        return (
          <>
            {movement.direction === 1 ? (
              <ArrowDownwardIcon color="success" />
            ) : (
              <ArrowUpward color="warning" />
            )}{' '}
            {movement.moved_quantity}
          </>
        );
      }
    },
    {
      field: 'product',
      headerName: t(`global.product`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'product__type__code',
      headerName: t(`global.product_type`),
      filterOperators,
      width: 150,
      renderCell: baseCell
    },
    {
      field: 'status',
      headerName: t(`global.status`),
      width: 150,
      renderCell: productStatusCell(t),
      filterable: false,
      sortable: false
    }
  ];

  if (sap) {
    columns.push({
      field: 'state',
      headerName: t(`global.sap_state`),
      width: 80,
      renderCell: sapStateCell,
      filterable: false,
      sortable: false
    });
  }

  columns.push(
    {
      field: 'label',
      headerName: t(`global.label`),
      width: 150,
      renderCell: baseCell,
      filterOperators,
      filterable: true,
      sortable: false
    },
    {
      field: 'description',
      headerName: t(`global.description`),
      filterOperators,
      sortable: false,
      width: 300,
      renderCell: baseCell
    },
    {
      field: 'timestamp',
      headerName: t(`${translationPrefix}.timestamp`),
      filterOperators: dateFilterOperators,
      type: 'date',
      width: 250,
      renderCell: dateCell
    },
    {
      field: 'discounted',
      headerName: t(`${translationPrefix}.discounted`),
      renderCell: booleanCell(t),
      filterOperators: booleanFilterOperators
    },
    {
      field: 'unit_price',
      headerName: t(`${translationPrefix}.unit_price`),
      filterOperators: numberFilterOperators,
      type: 'number',
      sortable: false,
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value, { significantDigits: 3 });
      },
      renderCell: (params: GridRenderCellParams) => currencyCell(params, 3)
    },
    {
      field: 'price',
      headerName: t(`${translationPrefix}.price`),
      filterOperators: numberFilterOperators,
      type: 'number',
      sortable: false,
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value, { significantDigits: 3 });
      },
      renderCell: (params: GridRenderCellParams) => currencyCell(params, 3)
    },
    {
      field: 'category',
      headerName: t(`entities.product.category`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'supplier',
      headerName: t(`global.supplier`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'warehouse',
      headerName: t(`global.warehouse`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'product_batch',
      headerName: t(`${translationPrefix}.product_batch`),
      filterOperators,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'expiration_date',
      headerName: t(`${translationPrefix}.expiration_date`),
      filterOperators: dateFilterOperators,
      valueFormatter: (params) => params.value && dayjs(params.value).format('DD/MM/YYYY'),
      editable: editableExpiration,
      type: 'date',
      width: 250,
      renderCell: dateCell
    },
    {
      field: 'notes',
      headerName: t(`global.notes`),
      width: 250,
      renderCell: baseCell,
      sortable: false,
      filterable: false
    },
    {
      field: 'product_notes',
      headerName: t(`global.product-notes`),
      width: 250,
      renderCell: baseCell,
      sortable: false,
      filterable: false
    },
    {
      field: 'type',
      headerName: t(`${translationPrefix}.type`),
      type: 'singleSelect',
      valueOptions: types,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'order_code',
      headerName: t(`entities.movement.order_code`),
      filterOperators,
      editable: canEdit,
      width: 250,
      renderCell: baseCell
    },
    {
      field: 'order_date',
      headerName: t(`${translationPrefix}.order_date`),
      editable: canEdit,
      width: 200,
      renderCell: dateCell
    },
    {
      field: 'transport_document',
      valueGetter: (params) => params.row.transport_document_ref,
      headerName: t(`${translationPrefix}.transport_document`),
      filterOperators,
      width: 200,
      editable: canEdit,
      renderCell: baseCell
    },
    {
      field: 'billable',
      headerName: t(`entities.ddt.billable`),
      filterOperators: booleanFilterOperators,
      width: 150,
      type: 'boolean',
      renderCell: booleanCell(t)
    },
    {
      field: 'invoice_ref',
      headerName: t(`entities.ddt.invoice_ref`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'invoice_date',
      headerName: t(`entities.ddt.invoice_date`),
      filterOperators: dateFilterOperators,
      width: 200,
      renderCell: dateCell
    },
    {
      field: 'invoiced_amount',
      headerName: t(`entities.ddt.amount`),
      type: 'number',
      width: 150,
      filterable: false,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams) => {
        return currencyFormatter(params.value, { significantDigits: 3 });
      },
      renderCell: (params: GridRenderCellParams) => currencyCell(params, 3)
    },
    {
      field: 'credit_memo_ref',
      headerName: t(`entities.ddt.credit_memo_ref`),
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: baseCell
    },
    {
      field: 'credit_memo_date',
      headerName: t(`entities.ddt.credit_memo_date`),
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: dateCell
    },
    {
      field: 'credit_memo_amount',
      headerName: t(`entities.ddt.credit_memo_amount`),
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: currencyCell
    },
    {
      field: 'transport_document_date',
      headerName: t(`${translationPrefix}.transport_document_date`),
      width: 200,
      type: 'date',
      renderCell: dateCell
    },
    {
      field: 'created_by',
      headerName: t(`${translationPrefix}.created_by`),
      filterOperators,
      width: 200,
      renderCell: baseCell
    }
  );

  return columns;
}
