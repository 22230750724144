import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { IOption, IProductWarehouse, IPurchaseOrderRow, IStockQuantity } from 'interfaces';
import PurchaseRequestLineForm from './PurchaseRequestLineForm';
import InfoBoxPurchaseRequestCard from './InfoBoxPurchaseRequestCard';

interface PurchaseRequestProps {
  productOptions: IOption[];
  contractOptions: IOption[];
  warehouseOptions: IOption[];
  maxUnits: number;
  maxDiscountedUnits: number;
  visibleProduct: {
    code: string;
    description?: string;
    stockQuantities?: IStockQuantity[];
    productWarehouse?: IProductWarehouse[];
    orderLines?: IPurchaseOrderRow[];
    consume?: any[];
    unitPrice?: string;
  } | null;
  onSubmitRow: (data: any) => void;
  setProductToAdd: (product: any) => void;
  setContractToAdd: (contract: any) => void;
  setWarehouseToAdd: (warehouse: any) => void;
}

const PurchaseRequestAccordion: React.FC<PurchaseRequestProps> = ({
  productOptions,
  contractOptions,
  warehouseOptions,
  maxUnits,
  maxDiscountedUnits,
  visibleProduct,
  onSubmitRow,
  setProductToAdd,
  setContractToAdd,
  setWarehouseToAdd
}) => {
  return (
    <Accordion className="bg-slate-50 my-8">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" color="primary">
          <b>{'Aggiungi elemento'}</b>{' '}
          {/* Qui usiamo direttamente il testo, ma puoi usare la funzione di traduzione */}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className="grid grid-cols-2 gap-2">
          {/* Prima colonna con il form per la richiesta di acquisto */}
          <div>
            <PurchaseRequestLineForm
              onSubmit={onSubmitRow}
              products={productOptions}
              setProduct={setProductToAdd}
              contracts={contractOptions}
              setContract={setContractToAdd}
              warehouses={warehouseOptions}
              setWarehouse={setWarehouseToAdd}
              maxUnits={maxUnits}
              maxDiscountedUnits={maxDiscountedUnits}
            />
          </div>

          {/* Seconda colonna con il card di informazioni sul prodotto */}
          <div className="m-2">
            <InfoBoxPurchaseRequestCard
              product_code={visibleProduct?.code || null}
              product_description={visibleProduct?.description || undefined}
              stockQuantities={visibleProduct?.stockQuantities || null}
              productWarehouse={visibleProduct?.productWarehouse || null}
              orderLines={visibleProduct?.orderLines || null}
              consumes={visibleProduct?.consume || null}
              unitPrice={visibleProduct?.unitPrice || null}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default PurchaseRequestAccordion;
