import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { IsTenantCoordinatorOrAdmin } from 'components/helpers/IsTenantCoordinator';
import NotificationWidget from 'components/widgets/NotificationWidget';
import ExpiringContracts from 'components/widgets/ExpiringContracts';
import ExpiringProducts from 'components/widgets/ExpiringProducts';
import DeliveryExpectedWidget from 'components/widgets/DeliveryExpectedWidget';
import LisStatusWidget from 'components/widgets/LisStatusWidget';
import PurchaseRequestsToApprove from 'components/widgets/PurchaseRequestsToApprove';
import OpenTickets from 'components/widgets/OpenTickets';
import DeliveriesWidget from 'components/widgets/DeliveriesWidget';
import ExpiringToday from 'components/widgets/ExpiringToday';
import ExpiringMaintenances from 'components/widgets/ExpiringMaintenances';
import UnderstockProducts from 'components/widgets/UnderstockProducts';
import PurchaseRequestsAutomatic from 'components/widgets/PurchaseRequestsAutomatic';
import SharepointLinkWidget from 'components/widgets/SharepointLinkWidget';

export default function TenantHome() {
  const { t } = useTranslation();
  const translationPrefix = 'dashboard';
  const tenant = process.env.REACT_APP_TENANT;

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      hideBreadcrumbs={true}>
      <div className="flex w-full flex-wrap">
        <IsTenantCoordinatorOrAdmin>
          {tenant && tenant == 'ROMA2' && (
            <div className="w-1/6 p-5">
              <SharepointLinkWidget />
            </div>
          )}
        </IsTenantCoordinatorOrAdmin>

        <IsTenantCoordinatorOrAdmin>
          <div className="w-1/6 p-5">
            <DeliveryExpectedWidget />
          </div>
        </IsTenantCoordinatorOrAdmin>

        <IsTenantCoordinatorOrAdmin>
          <div className="w-1/6 p-5">
            <PurchaseRequestsToApprove />
          </div>
        </IsTenantCoordinatorOrAdmin>

        <IsTenantCoordinatorOrAdmin>
          <div className="w-1/6 p-5">
            <PurchaseRequestsAutomatic />
          </div>
        </IsTenantCoordinatorOrAdmin>

        <IsTenantAdministrator>
          <div className="w-1/6 p-5">
            <LisStatusWidget />
          </div>
        </IsTenantAdministrator>

        <div className="w-1/6 p-5">
          <OpenTickets />
        </div>

        <div className="w-full flex flex-wrap">
          <div className="w-full p-4">
            <ExpiringToday />
          </div>
        </div>

        <div className="w-full flex flex-wrap">
          <IsTenantAdministrator>
            <div className="w-1/4 p-4">
              <ExpiringContracts />
            </div>
            <div className="w-1/4 p-4">
              <ExpiringMaintenances />
            </div>
          </IsTenantAdministrator>

          <div className={'w-1/4 p-4'}>
            <ExpiringProducts />
          </div>

          <div className="w-1/4 p-4">
            <UnderstockProducts />
          </div>
        </div>

        <div className="w-full p-4">
          <DeliveriesWidget />
        </div>

        <div className="w-full p-4">
          <NotificationWidget />
        </div>
      </div>
    </TenantPage>
  );
}
