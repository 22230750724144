import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface PreviewDialogProps {
  open: boolean;
  onClose: () => void;
  fulfillRequest: (sendEmail: boolean) => void;
  getOrderPreviewInnerContent: () => React.ReactNode;
}

const SendOrderDialog: React.FC<PreviewDialogProps> = ({
  open,
  onClose,
  fulfillRequest,
  getOrderPreviewInnerContent
}) => {
  const { t } = useTranslation(); // Assuming you're using react-i18next for translations

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle id="alert-dialog-title">{'Anteprima ordine'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>{t('pages.purchase-requests.new.order-preview-description')}</p>
          {getOrderPreviewInnerContent()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="w-full flex justify-between">
          <Button onClick={onClose}>{t('actions.cancel')}</Button>
          <div>
            <Button className="me-2" onClick={() => fulfillRequest(false)}>
              {t('actions.confirm')}
            </Button>
            <Button variant="contained" onClick={() => fulfillRequest(true)} autoFocus>
              {t('actions.confirm-send-email')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SendOrderDialog;
