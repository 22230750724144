import ReactFinalForm from 'components/form/ReactFinalForm';
import { IPurchaseRequestRow } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { required } from 'helpers/validators';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { DatePickerAdapter } from 'components/form/DatePickerAdapter';

interface PurchaseRequestLineParams {
  purchaseRequestLine: IPurchaseRequestRow;
  onSubmit: any;
}

function EditPurchaseRequestLine(params: PurchaseRequestLineParams) {
  const { t } = useTranslation();
  const { purchaseRequestLine, onSubmit } = params;

  return (
    <div>
      <div className="align-middle p-2">
        <ReactFinalForm onSubmit={onSubmit} formId="editPurchaseRequestLine" hideControls>
          <div className="p-2 ">
            <IdentityField
              name="quantity"
              label={t('global.quantity')}
              component={TextFieldAdapter}
              type="number"
              defaultValue={purchaseRequestLine.quantity}
              validate={required}
            />
          </div>
          <div className="p-2 ">
            <IdentityField
              name="static_unit_price"
              label={t(`entities.purchase_request.unit_price`)}
              component={TextFieldAdapter}
              type="number"
              defaultValue={purchaseRequestLine.static_unit_price}
              initialValue={purchaseRequestLine.static_unit_price}
              validate={required}
            />
          </div>
          <div className="p-2 ">
            <IdentityField
              name="requested_shipment_date"
              component={DatePickerAdapter}
              label={t(`entities.purchase_request.requested_shipment_date`)}
              defaultValue={purchaseRequestLine.requested_shipment_date}
            />
          </div>
          <div className="p-2 ">
            <IdentityField
              name="notes"
              label={t(`global.notes`)}
              initialValue={purchaseRequestLine.notes}
              component={TextFieldAdapter}
            />
          </div>
        </ReactFinalForm>
      </div>
    </div>
  );
}

export default EditPurchaseRequestLine;
