import ReactFinalForm from 'components/form/ReactFinalForm';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { IMedicalServiceEtlField } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';

interface MedicalServiceEtlFieldFormProps {
  transcode: IMedicalServiceEtlField;
  onSubmit: any;
}
function MedicalServiceEtlFieldForm(props: MedicalServiceEtlFieldFormProps) {
  const { t } = useTranslation();
  const { transcode, onSubmit } = props;
  const fields_choices = [
    { code: 'quantity', description: 'Quantità' },
    { code: 'service_date', description: 'Data esecuzione' },
    { code: 'request_date', description: 'Data richiesta' },
    { code: 'regional_code', description: 'Codice regionale' },
    { code: 'exam_code', description: 'Codice esame' },
    { code: 'exam', description: 'Esame' },
    { code: 'req_department', description: 'Reparto richiedente' },
    { code: 'req_laboratory', description: 'Laboratorio richiedente' },
    { code: 'exec_laboratory', description: 'Laboratorio esecutore' },
    { code: 'workstation', description: 'Postazione' },
    { code: 'extra_1', description: 'Extra 1' },
    { code: 'extra_2', description: 'Extra 2' },
    { code: 'extra_3', description: 'Extra 3' },
    { code: 'extra_4', description: 'Extra 4' },
    { code: 'extra_5', description: 'Extra 5' },
    { code: 'extra_6', description: 'Extra 6' },
    { code: 'extra_7', description: 'Extra 7' }
  ];

  return (
    <div>
      {transcode && (
        <ReactFinalForm onSubmit={onSubmit} edit={!!transcode.id}>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 p-0 md:pr-4">
              <IdentityField
                name="field_to"
                component={AutocompleteAdapter}
                label={t('global.field_to')}
                options={fields_choices}
                optionValue="code"
                optionLabel="description"
                type="text"
                required
              />
            </div>
            <div className="w-full md:w-1/3 p-0 md:pr-4">
              <IdentityField
                name="field_from"
                component={TextFieldAdapter}
                label={t('global.field_from')}
                type="text"
                required
              />
            </div>
            <div className="w-full md:w-1/3 ">
              <IdentityField
                name="transcode"
                component={TextFieldAdapter}
                label={t('global.transcode')}
                placeholder={t('global.transcode')}
                type="text"
              />
            </div>
          </div>
        </ReactFinalForm>
      )}
    </div>
  );
}

export default MedicalServiceEtlFieldForm;
