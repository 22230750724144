import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { GridColDef } from '@mui/x-data-grid-premium';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { useRef } from 'react';
import { medicalServiceEtlFieldService } from 'services/api/MedicalServiceEtlFieldService';
import { MedicalServicesEtlFieldColumns } from 'components/common/enhanced/common-headers/medical-services-etl-transcodes';
import { Button, ButtonGroup } from '@mui/material';
import { IsTenantAdministrator } from 'components/helpers/IsTenantAdministrator';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import toast from 'features/toast';

export const MedicalServiceEtlFields = () => {
  const translationPrefix = 'pages.extra-field-transcode';
  const datagridRefresh = useRef(null);

  const { t } = useTranslation();

  const handleDelete = (id: number) => {
    medicalServiceEtlFieldService.delete(id).then(() => {
      toast.success(t('actions.successfully.deleted'));
      datagridRefresh.current();
    });
  };

  const columns: GridColDef[] = MedicalServicesEtlFieldColumns(handleDelete);

  return (
    <>
      <TenantPage
        title={t(`${translationPrefix}.title`)}
        subtitle={t(`${translationPrefix}.subtitle`)}
        menuRight={
          <div className="flex justify-end items-center">
            <ButtonGroup>
              <IsTenantAdministrator>
                <Link to="new">
                  <Button color="primary" size="small">
                    <AddIcon />
                    {t(`${translationPrefix}.add`)}
                  </Button>
                </Link>
              </IsTenantAdministrator>
            </ButtonGroup>
          </div>
        }>
        <div className="mt-6">
          <EnhancedDataGrid
            columns={columns}
            service={medicalServiceEtlFieldService}
            refresh={datagridRefresh}
          />
        </div>
      </TenantPage>
    </>
  );
};

export default MedicalServiceEtlFields;
