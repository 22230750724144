import { IMedicalServiceEtlField } from 'interfaces';
import { BaseService } from './BaseService';

class MedicalServiceEtlFieldService extends BaseService<IMedicalServiceEtlField> {
  constructor() {
    super('medical-service-etl-fields');
  }
  canBulkDelete = true;
}

export const medicalServiceEtlFieldService = new MedicalServiceEtlFieldService();
