import ReactFinalForm from 'components/form/ReactFinalForm';
import { IOption, ITransportActiveDocument, ITransportDocument } from 'interfaces';
import { IdentityField } from 'components/form/IdentityField';
import { useTranslation } from 'react-i18next';
import { TextFieldAdapter } from 'components/form/TextFieldAdapter';
import { useEffect, useState } from 'react';
import { AutocompleteAdapter } from 'components/form/AutocompleteAdapter';
import { stockQuantityService } from 'services/api';
import { Typography } from '@mui/material';

interface UpdateTransportActiveDocumentLineDialogFormParams {
  trasportActiveDocumentLine: any;
  locations: IOption[];
  ddt: ITransportActiveDocument;
  onSubmit: any;
}

// Function component to update contract line details
function UpdateTransportActiveDocumentLineDialog(
  params: UpdateTransportActiveDocumentLineDialogFormParams
) {
  const { t } = useTranslation();
  const { trasportActiveDocumentLine, locations, ddt, onSubmit } = params;
  const [batchesOption, setBatchesOptions] = useState<IOption[]>([]);
  //const [maxUnits, setMaxUnits] = useState<number | null>(null);
  const [selectedBatch, setSelectedBatch] = useState<IOption | null>(null);

  // useEffect(() => {
  //   if (selectedBatch) {
  //     setMaxUnits(selectedBatch['qty']);
  //   }
  // }, [selectedBatch]);

  // Calculate the batches options
  useEffect(() => {
    const p = {
      product__id: trasportActiveDocumentLine?.product ?? '',
      warehouse__id: ddt.warehouse_out ?? '',
      quantity__gt: 0
    };
    stockQuantityService.getUnloadStocks(1000, 0, p).then((res) => {
      setBatchesOptions(
        res.results.map((value) => {
          let description = value.product_batch;
          if (value.expiration_date) description += `; Scad: ${value.expiration_date}`;
          description += `; Qty: ${value.total_quantity}`;
          return {
            id: value.product_batch,
            code: description,
            expiration_date: value.expiration_date,
            qty: value.total_quantity
          };
        })
      );
    });
  }, []);

  // Translation prefix for easier access to translation keys DA METTERE
  const translationPrefix = 'pages.transport-active-document-line.edit';

  // Function to handle form submission
  const onSubmitTrasportActiveDocumentLine = async (values) => {
    const notes = values['location']?.code ?? '';
    const params = {
      product_batch: values['product_batch'].id,
      expiration_date: values['product_batch'].expiration_date,
      location: values['location']?.id ?? null,
      notes: notes,
      quantity: values['quantity']
    };
    onSubmit(trasportActiveDocumentLine.id, params);
  };

  return (
    <div>
      {/* Form component to handle updating contract line */}
      <ReactFinalForm
        onSubmit={onSubmitTrasportActiveDocumentLine}
        formId="updateTransportActiveDocumentLine"
        hideControls>
        <div className="flex flex-wrap">
          <div className="w-1/2 mb-4 pr-4 pt-2">
            <IdentityField
              name="product_batch"
              label={t(`${translationPrefix}.product_batch`)}
              component={AutocompleteAdapter}
              options={batchesOption}
              type="text"
              required
              setValue={setSelectedBatch}
              submitObject
              initValue={batchesOption.find(
                (b) => b.id === trasportActiveDocumentLine.product_batch
              )}
            />
          </div>
          <div className="w-1/2 mb-4 pr-4 pt-2">
            <IdentityField
              name="location"
              component={AutocompleteAdapter}
              options={locations}
              optionValue="code"
              optionLabel="description"
              label={t(`${translationPrefix}.location`)}
              type="text"
              initValue={locations.find(
                (location) => location.id === trasportActiveDocumentLine.location
              )}
              submitObject
            />
          </div>
          <div className="w-1/4 mb-4 pr-4 ">
            {/* {maxUnits !== null && (
              <Typography color="text-primary-600">
                <b>{`${t(`pages.active-ddt.new-product.aviable_units`)}: ${maxUnits}`}</b>
              </Typography>
            )} */}
            <IdentityField
              component={TextFieldAdapter}
              name="quantity"
              label={t('global.quantity')}
              type="text"
              defaultValue={trasportActiveDocumentLine.quantity}
            />
          </div>
        </div>
      </ReactFinalForm>
    </div>
  );
}

export default UpdateTransportActiveDocumentLineDialog;
