import { ITransportActiveDocument } from 'interfaces';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import { useNavigate } from 'react-router-dom';
import TransportActiveDocumentForm from '../TransportActiveDocumentForm';
import { transportActiveDocumentService } from 'services/api/TransportActiveDocumentService';
import toast from 'features/toast';

const initDdt: ITransportActiveDocument = {
  id: null,
  ref: '',
  status: null
};

export const CreateTransportActiveDocument = () => {
  const translationPrefix = 'pages.active-ddt.new';

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    transportActiveDocumentService
      .add({ ...values, warehouse_out: values.warehouse_out['id'], order: values.order }) // warehouse_out is a IWarehouse object, we need to get only PK
      .then((res) => {
        if (res?.id) {
          if ('warnings' in res) {
            res.warnings.forEach((warning) => {
              toast.warning(warning);
            });
          } else {
            toast.success(t(`${translationPrefix}.success`));
          }
          navigate(`../${res.id}`);
        }
      });
  };

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}>
      <TransportActiveDocumentForm
        transport_document={initDdt}
        onSubmit={onSubmit}
        isUpdate={false}
      />
    </TenantPage>
  );
};

export default CreateTransportActiveDocument;
